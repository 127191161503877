import createPrimitiveStore from './createPrimitiveStore';

export default function createStore(initialState) {
  const { setState: setPrimitiveState, ...store } = createPrimitiveStore(
    initialState
  );

  function getPartialState(arg) {
    switch (typeof arg) {
      case 'object':
        return arg;
      case 'function':
        return arg(store.getState());
      default:
        throw Error(
          'setState received invalid argument:' +
            ' Should be partial state object or function.'
        );
    }
  }
  function setState(arg) {
    setPrimitiveState({ ...store.getState(), ...getPartialState(arg) });
  }

  return {
    ...store,
    setState,
  };
}
