import ingredients from 'data/ingredientsWithFullEffects';
import { unique, order } from 'util/array';
import * as ls from 'util/localStorage';

const dlcOrder = [
  'Skyrim',
  'Dawnguard',
  'Dragonborn',
  'Hearthfire',
  'Quest*',
  'Fishing Creation',
  'Goblins Creation',
  'Plague of the Dead Creation',
  'Rare Curios Creation',
  'Saints & Seducers Creation',
  'The Cause Creation',
];

const actualDLCs = ingredients
  .map(i => i.dlc)
  .filter(Boolean)
  .filter(unique);

// Include 'Skyrim' even though not actually a DLC
export const availableDLCs = ['Skyrim']
  .concat(actualDLCs)
  .sort(order('ascending', dlc => dlcOrder.indexOf(dlc)));

const defaultDLCs = ['Skyrim', 'Dawnguard', 'Dragonborn', 'Hearthfire'];

export const load = () => ls.getJson('selectedDLCs') || defaultDLCs;
export const unload = data => ls.setJson('selectedDLCs', data);
