import { inverseIntersection, hasIntersection } from 'util/array';

const hasCommonEffectWith = ingredient => otherIngredient => {
  return hasIntersection(ingredient.effects, otherIngredient.effects);
};

const addsEffectTo = (first, second) => {
  const unused = inverseIntersection(first.effects, second.effects);
  return third => hasIntersection(third.effects, unused);
};

const createsTrioBetterThanAnySubPair = ([first, second]) => third => {
  return (
    addsEffectTo(first, second)(third) &&
    addsEffectTo(first, third)(second) &&
    addsEffectTo(second, third)(first)
  );
};

const isNotDuplicateOfAPreviousTrio = (allIngredients, pair) => third => {
  /*
   * If the first and third ingredients combine, it may be a duplicate.
   * Only keep the version where the three ingredients are in order.
   *
   * Example:
   * [A,B] and [A,C] are valid combinations, hence also [A,B,C] and
   * [A,C,B]. We want to omit the latter as it is a duplicate just with
   * different order.
   *
   * Also works if second or third combines with all, or if all combine.
   */

  const [first, second] = pair;

  if (hasCommonEffectWith(third)(first)) {
    return allIngredients.indexOf(second) < allIngredients.indexOf(third);
  }

  return true;
};

function getAllUsefulCombinations(allIngredients) {
  const pairs = allIngredients.flatMap((ingredient, index) =>
    allIngredients
      .slice(index + 1)
      .filter(hasCommonEffectWith(ingredient))
      .map(second => [ingredient, second])
  );

  const trios = pairs.flatMap(pair => {
    const firstIndex = allIngredients.indexOf(pair[0]);

    return allIngredients
      .slice(firstIndex + 1)
      .filter(i => i !== pair[1])
      .filter(createsTrioBetterThanAnySubPair(pair))
      .filter(isNotDuplicateOfAPreviousTrio(allIngredients, pair))
      .map(third => pair.concat(third));
  });

  return pairs.concat(trios);
}

export function getIngredientCombos(allIngredients) {
  return getAllUsefulCombinations(allIngredients);
}
