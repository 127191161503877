import React from 'react';
import AvailableIngredients from './AvailableIngredients/AvailableIngredients';
import UnavailableIngredients from './UnavailableIngredients/UnavailableIngredients';

export default function Ingredients() {
  return (
    <div>
      <AvailableIngredients />
      <UnavailableIngredients />
    </div>
  );
}
