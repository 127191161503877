import React from 'react';

const filterButton = (
  <button>
    <span role="img" aria-label="filter and sort">
      🔍
    </span>
  </button>
);

export default filterButton;
