import React, { useMemo } from 'react';

function generateStyle() {
  const hex = Math.random()
    .toString(16)
    .slice(2, 4);

  return {
    width: 30 + 80 * Math.random(),
    height: 20,
    backgroundColor: `#005bbb${hex}`,
    borderRadius: '2px',
  };
}

export default function FakeContent() {
  return <div style={useMemo(generateStyle, [])} />;
}
