import React from 'react';
import * as I from './Input.style.js';

import filterStore from './filterStore';

export default function FilterValue({ filter }) {
  return (
    <I.ValueInput
      type="text"
      className={filter.value === '' ? 'empty' : 'filled'}
      value={filter.value}
      placeholder="Min"
      onChange={event => filterStore.setState({ value: event.target.value })}
    />
  );
}
