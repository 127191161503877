import createLazyMappedStore from './createLazyMappedStore';

export default function createPrimitiveStore(initialState) {
  let state = initialState;
  let listeners = [];
  let ticket = {};

  function emit() {
    listeners.forEach(notify => notify(state, ticket));
  }

  function getState() {
    return state;
  }
  function getStateAndTicket() {
    return [state, ticket];
  }
  function setState(nextState) {
    if (nextState === state) {
      return;
    }
    state = nextState;
    ticket = {};
    emit();
  }

  function subscribe(fn) {
    listeners.push(fn);
    return () => unsubscribe(fn);
  }
  function unsubscribe(fn) {
    listeners = listeners.filter(remaining => remaining !== fn);
  }
  function retroSubscribe(fn) {
    fn(state, ticket);
    return subscribe(fn);
  }

  function checkIn(lastTicket, fn) {
    if (lastTicket !== ticket && fn) {
      fn(state, ticket);
    }
  }

  function map(transform) {
    return createLazyMappedStore(
      { subscribe, unsubscribe, checkIn },
      transform
    );
  }

  return {
    getState,
    getStateAndTicket,
    setState,
    subscribe,
    unsubscribe,
    retroSubscribe,
    map,
  };
}
