import React from 'react';
import * as S from './Potions.style.js';
import IngredientToggler from './IngredientToggler/IngredientToggler';
import PotionTable from './PotionTable/PotionTable';
import PotionListMobile from './PotionListMobile/PotionListMobile';

import useResizeSelector from './useResizeSelector';

export default function Potions() {
  const isMobile = useResizeSelector(() => window.innerWidth <= 768, []);

  return (
    <S.Content>
      <IngredientToggler />

      <S.PreventShrink>
        {isMobile ? <PotionListMobile /> : <PotionTable />}
      </S.PreventShrink>
    </S.Content>
  );
}
