import React from 'react';
import * as S from './DLCs.style';

import { useStore } from 'pocki2';
import store from 'store';
import { availableDLCs } from 'store/dlc';
import { toggle, unique } from 'util/array';

function toggleDLC(dlc) {
  store.setState(state => ({
    selectedDLCs: toggle(state.selectedDLCs, dlc),
  }));
}

const selectedDLCsStore = store.map(state => state.selectedDLCs);

const nonDLCs = ['Skyrim', 'Quest*'];
const anniversaryDLCs = availableDLCs.filter(dlc => !nonDLCs.includes(dlc));

export default function DLCs() {
  const selectedDLCs = useStore(selectedDLCsStore);

  const anniversaryEditionSelected = anniversaryDLCs.every(dlc =>
    selectedDLCs.includes(dlc)
  );

  function toggleAnniversaryEditionSelected() {
    if (anniversaryEditionSelected) {
      store.setState(state => ({
        selectedDLCs: state.selectedDLCs.filter(
          dlc => !anniversaryDLCs.includes(dlc)
        ),
      }));
    } else {
      store.setState(state => ({
        selectedDLCs: state.selectedDLCs.concat(anniversaryDLCs).filter(unique),
      }));
    }
  }

  return (
    <S.Section>
      <S.MakeAllFieldsetsSameWidth>
        <S.Fieldset>
          <legend>Vanilla</legend>
          {nonDLCs.map(dlc => (
            <label key={dlc} htmlFor={dlc} onClick={() => toggleDLC(dlc)}>
              {dlc} {['Rare Curios Creation'].includes(dlc) && <S.Beta />}
              <input
                type="checkbox"
                checked={selectedDLCs.includes(dlc)}
                onChange={() => {}}
              />
            </label>
          ))}
        </S.Fieldset>
        <S.Fieldset>
          <legend>
            <label
              htmlFor="Skyrim Anniversary Edition"
              onClick={() => toggleAnniversaryEditionSelected()}
            >
              Skyrim Anniversary Edition
              <input
                type="checkbox"
                checked={anniversaryEditionSelected}
                onChange={() => {}}
              />
            </label>
          </legend>

          {anniversaryDLCs.map(dlc => (
            <label key={dlc} htmlFor={dlc} onClick={() => toggleDLC(dlc)}>
              {dlc} {['Rare Curios Creation'].includes(dlc) && <S.Beta />}
              <input
                type="checkbox"
                checked={selectedDLCs.includes(dlc)}
                onChange={() => {}}
              />
            </label>
          ))}
        </S.Fieldset>
      </S.MakeAllFieldsetsSameWidth>
    </S.Section>
  );
}
