import React from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import UkraineBanner from './UkraineBanner';

import * as T from 'styles/Tabs/Tabs.style.js';

import Ingredients from 'routes/Ingredients/Ingredients';
import Potions from 'routes/Potions/Potions';
import Effects from 'routes/Effects/Effects';
import Skills from 'routes/Skills/Skills';
import DLCs from 'routes/DLCs/DLCs';
import Locales from 'routes/Locales/Locales';

const history = createBrowserHistory();

history.listen(location => {
  if (window.ga) {
    window.ga('send', 'pageview', location.pathname);
  }
});

export default function App() {
  return (
    <>
      <UkraineBanner />
      <Router history={history}>
        <header>
          <T.TabBar>
            <T.TabLink exact to="/">
              Potions
            </T.TabLink>
            <T.TabLink to="skills">Skills</T.TabLink>
            <T.TabLink to="ingredients">Ingredients</T.TabLink>
            <T.TabLink to="effects">Effects</T.TabLink>
            <T.TabLink to="dlc">DLCs</T.TabLink>
            <T.TabLink to="locale">
              <span role="img" aria-label="locale">
                🌍
              </span>
            </T.TabLink>
          </T.TabBar>
        </header>
        <T.TabContent>
          <Switch>
            <Route exact path="/" component={Potions} />
            <Route exact path="/skills" component={Skills} />
            <Route exact path="/ingredients" component={Ingredients} />
            <Route exact path="/effects" component={Effects} />
            <Route exact path="/dlc" component={DLCs} />
            <Route exact path="/locale" component={Locales} />
            <Redirect to="/" />
          </Switch>
        </T.TabContent>
      </Router>
    </>
  );
}
