import React from 'react';
import * as I from './Input.style.js';
import Modal from 'components/Modal/Modal';
import FilterModalContent from './FilterModalContent';
import filterButton from './filterButton';

import { useStore } from 'pocki2';
import filterStore from './filterStore';
import { effectsForSelectedDLCsStore } from 'routes/Effects/Effects';

const effectNameStore = effectsForSelectedDLCsStore.map(effects =>
  effects.map(effect => effect.name)
);

export default function FilterEffects({ filter }) {
  const effectNames = useStore(effectNameStore);

  return (
    <I.Wrapper>
      <I.Input
        type="text"
        className={filter.effects === '' ? 'empty' : 'filled'}
        placeholder="Includes"
        value={filter.effects}
        onChange={event =>
          filterStore.setState({ effects: event.target.value })
        }
      />
      <Modal
        content={props => (
          <FilterModalContent
            close={props.close}
            thing="Effect"
            enableMagnitudeSorting={true}
            suggestions={effectNames}
            subFilter={filter.effects}
            onChange={effects => filterStore.setState({ effects })}
          />
        )}
      >
        {filterButton}
      </Modal>
    </I.Wrapper>
  );
}
