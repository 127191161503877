export default [
  {
    name: 'Cure Disease',
    concoction: 'Potion',
    description: 'Cures all diseases.',
    baseCost: 0.5,
    magnitude: 5,
    duration: 0,
    valueAt100Skill: 21,
    fixed: 'duration',
  },
  {
    name: 'Cure Poison',
    'DLCs': ['Rare Curios Creation'],
    concoction: 'Potion',
    description: "Stops poison's continuing effects.",
    baseCost: 0.2,
    magnitude: 2,
    duration: 0,
    valueAt100Skill: 3,
    fixed: 'N/A',
  },
  {
    name: 'Damage Health',
    concoction: 'Poison',
    description: 'Causes <mag> points of poison damage.',
    baseCost: 3,
    magnitude: 2,
    duration: 1,
    valueAt100Skill: 3,
    fixed: 'duration',
  },
  {
    name: 'Damage Magicka',
    concoction: 'Poison',
    description: "Drains the target's Magicka by <mag> points.",
    baseCost: 2.2,
    magnitude: 3,
    duration: 0,
    valueAt100Skill: 52,
    fixed: 'duration',
  },
  {
    name: 'Damage Magicka Regen',
    concoction: 'Poison',
    description:
      "Decrease the target's Magicka regeneration by <mag>% for <dur> seconds.",
    baseCost: 0.5,
    magnitude: 100,
    duration: 5,
    valueAt100Skill: 265,
    fixed: 'magnitude',
  },
  {
    name: 'Damage Stamina',
    concoction: 'Poison',
    description: "Drain the target's Stamina by <mag> points.",
    baseCost: 1.8,
    magnitude: 3,
    duration: 0,
    valueAt100Skill: 43,
    fixed: 'duration',
  },
  {
    name: 'Damage Stamina Regen',
    concoction: 'Poison',
    description:
      "Decrease the target's Stamina regeneration by <mag>% for <dur> seconds.",
    baseCost: 0.3,
    magnitude: 100,
    duration: 5,
    valueAt100Skill: 159,
    fixed: 'magnitude',
  },
  {
    name: 'Fear',
    concoction: 'Poison',
    description:
      'Creatures and people up to level <mag> flee from combat for <dur> seconds.',
    baseCost: 5,
    magnitude: 1,
    duration: 30,
    valueAt100Skill: 120,
    fixed: 'duration',
  },
  {
    name: 'Fortify Alteration',
    concoction: 'Potion',
    description: 'Alteration spells last <mag>% longer for <dur> seconds.',
    baseCost: 0.2,
    magnitude: 4,
    duration: 60,
    valueAt100Skill: 47,
    fixed: 'duration',
  },
  {
    name: 'Fortify Barter',
    concoction: 'Potion',
    description: 'You haggle for <mag>% better prices for <dur> seconds.',
    baseCost: 2,
    magnitude: 1,
    duration: 30,
    valueAt100Skill: 48,
    fixed: 'duration',
  },
  {
    name: 'Fortify Block',
    concoction: 'Potion',
    description: 'Blocking absorbs <mag>% more damage for <dur> seconds.',
    baseCost: 0.5,
    magnitude: 4,
    duration: 60,
    valueAt100Skill: 118,
    fixed: 'duration',
  },
  {
    name: 'Fortify Carry Weight',
    concoction: 'Potion',
    description: 'Carrying capacity increases by <mag> for <dur> seconds.',
    baseCost: 0.15,
    magnitude: 4,
    duration: 300,
    valueAt100Skill: 208,
    fixed: 'duration',
  },
  {
    name: 'Fortify Conjuration',
    concoction: 'Potion',
    description: 'Conjurations spells last <mag>% longer for <dur> seconds.',
    baseCost: 0.25,
    magnitude: 5,
    duration: 60,
    valueAt100Skill: 75,
    fixed: 'duration',
  },
  {
    name: 'Fortify Destruction',
    concoction: 'Potion',
    description: 'Destruction spells are <mag>% stronger for <dur> seconds.',
    baseCost: 0.5,
    magnitude: 5,
    duration: 60,
    valueAt100Skill: 151,
    fixed: 'duration',
  },
  {
    name: 'Fortify Enchanting',
    concoction: 'Potion',
    description: 'For <dur> seconds, items are enchanted <mag>% stronger.',
    baseCost: 0.6,
    magnitude: 1,
    duration: 30,
    valueAt100Skill: 14,
    fixed: 'duration',
  },
  {
    name: 'Fortify Health',
    concoction: 'Potion',
    description: 'Health is increased by <mag> points for <dur> seconds.',
    baseCost: 0.35,
    magnitude: 4,
    duration: 60,
    valueAt100Skill: 82,
    fixed: 'duration',
  },
  {
    name: 'Fortify Heavy Armor',
    concoction: 'Potion',
    description:
      'Increase Heavy Armor skill by <mag> points for <dur> seconds.',
    baseCost: 0.5,
    magnitude: 2,
    duration: 60,
    valueAt100Skill: 55,
    fixed: 'duration',
  },
  {
    name: 'Fortify Illusion',
    concoction: 'Potion',
    description: 'Illusion spells are <mag>% stronger for <dur> seconds.',
    baseCost: 0.4,
    magnitude: 4,
    duration: 60,
    valueAt100Skill: 94,
    fixed: 'duration',
  },
  {
    name: 'Fortify Light Armor',
    concoction: 'Potion',
    description:
      'Increases Light Armor skill by <mag> points for <dur> seconds.',
    baseCost: 0.5,
    magnitude: 2,
    duration: 60,
    valueAt100Skill: 55,
    fixed: 'duration',
  },
  {
    name: 'Fortify Lockpicking',
    concoction: 'Potion',
    description: 'Lockpicking is <mag>% easier for <dur> seconds.',
    baseCost: 0.5,
    magnitude: 2,
    duration: 30,
    valueAt100Skill: 25,
    fixed: 'duration',
  },
  {
    name: 'Fortify Magicka',
    concoction: 'Potion',
    description: 'Magicka is increased by <mag> points for <dur> seconds.',
    baseCost: 0.3,
    magnitude: 4,
    duration: 60,
    valueAt100Skill: 71,
    fixed: 'duration',
  },
  {
    name: 'Fortify Marksman',
    concoction: 'Potion',
    description: 'Bows do <mag>% more damage for <dur> seconds.',
    baseCost: 0.5,
    magnitude: 4,
    duration: 60,
    valueAt100Skill: 118,
    fixed: 'duration',
  },
  {
    name: 'Fortify One-handed',
    concoction: 'Potion',
    description: 'One-handed weapons do <mag>% more damage for <dur> seconds.',
    baseCost: 0.5,
    magnitude: 4,
    duration: 60,
    valueAt100Skill: 118,
    fixed: 'duration',
  },
  {
    name: 'Fortify Persuasion',
    'DLCs': ['Fishing Creation'],
    concoction: 'Potion',
    description: '+<mag> Speechcraft for <dur> seconds.',
    baseCost: 0.5,
    magnitude: 1,
    duration: 30,
    valueAt100Skill: 1,
    fixed: 'N/A',
  },
  {
    name: 'Fortify Pickpocket',
    concoction: 'Potion',
    description: 'Pickpocketing is <mag>% easier for <dur> seconds.',
    baseCost: 0.5,
    magnitude: 4,
    duration: 60,
    valueAt100Skill: 118,
    fixed: 'duration',
  },
  {
    name: 'Fortify Restoration',
    concoction: 'Potion',
    description: 'Restoration spells are <mag>% stronger for <dur> seconds.',
    baseCost: 0.5,
    magnitude: 4,
    duration: 60,
    valueAt100Skill: 118,
    fixed: 'duration',
  },
  {
    name: 'Fortify Smithing',
    concoction: 'Potion',
    description:
      'For <dur> seconds, weapon and armor improving is <mag>% better.',
    baseCost: 0.75,
    magnitude: 4,
    duration: 30,
    valueAt100Skill: 82,
    fixed: 'duration',
  },
  {
    name: 'Fortify Sneak',
    concoction: 'Potion',
    description: 'You are <mag>% harder to detect for <dur> seconds.',
    baseCost: 0.5,
    magnitude: 4,
    duration: 60,
    valueAt100Skill: 118,
    fixed: 'duration',
  },
  {
    name: 'Fortify Stamina',
    concoction: 'Potion',
    description: 'Stamina is increased by <mag> points for <dur> seconds.',
    baseCost: 0.3,
    magnitude: 4,
    duration: 60,
    valueAt100Skill: 71,
    fixed: 'duration',
  },
  {
    name: 'Fortify Two-handed',
    concoction: 'Potion',
    description: 'Two-handed weapons do <mag>% more damage for <dur> seconds.',
    baseCost: 0.5,
    magnitude: 4,
    duration: 60,
    valueAt100Skill: 118,
    fixed: 'duration',
  },
  {
    name: 'Frenzy',
    concoction: 'Poison',
    description:
      'Creatures and people up to level <mag> will attack anything nearby for <dur> seconds.',
    baseCost: 15,
    magnitude: 1,
    duration: 10,
    valueAt100Skill: 107,
    fixed: 'duration',
  },
  {
    name: 'Invisibility',
    concoction: 'Potion',
    description: 'Invisibility for <dur> seconds.',
    baseCost: 100,
    magnitude: 0,
    duration: 4,
    valueAt100Skill: 261,
    fixed: 'magnitude',
  },
  {
    name: 'Light',
    'DLCs': ['Rare Curios Creation'],
    concoction: 'Potion',
    description: 'Emits a small light that lasts for <dur> seconds.',
    baseCost: 1,
    magnitude: 1,
    duration: 1,
    valueAt100Skill: 25,
    fixed: 'N/A',
  },
  {
    name: 'Lingering Damage Health',
    concoction: 'Poison',
    description: 'Causes <mag> points of poison damage for <dur> seconds.',
    baseCost: 12,
    magnitude: 1,
    duration: 10,
    valueAt100Skill: 86,
    fixed: 'duration',
  },
  {
    name: 'Lingering Damage Magicka',
    concoction: 'Poison',
    description:
      "Drains the target's Magicka by <mag> points per second for <dur> seconds.",
    baseCost: 10,
    magnitude: 1,
    duration: 10,
    valueAt100Skill: 71,
    fixed: 'duration',
  },
  {
    name: 'Lingering Damage Stamina',
    concoction: 'Poison',
    description:
      "Drain the target's Stamina by <mag> points per second for <dur> seconds.",
    baseCost: 1.8,
    magnitude: 1,
    duration: 10,
    valueAt100Skill: 12,
    fixed: 'duration',
  },
  {
    name: 'Night Eye',
    'DLCs': ['Rare Curios Creation'],
    concoction: 'Potion',
    description: 'Improved night vision for <dur> seconds.',
    baseCost: 1,
    magnitude: 1,
    duration: 1,
    valueAt100Skill: 38,
    fixed: 'N/A',
  },
  {
    name: 'Paralysis',
    concoction: 'Poison',
    description: 'Target is paralyzed for <dur> seconds.',
    baseCost: 500,
    magnitude: 0,
    duration: 1,
    valueAt100Skill: 285,
    fixed: 'magnitude',
  },
  {
    name: 'Ravage Health',
    concoction: 'Poison',
    description: 'Causes <mag> points of concentrated poison damage.',
    baseCost: 0.4,
    magnitude: 2,
    duration: 10,
    valueAt100Skill: 6,
    fixed: 'duration',
  },
  {
    name: 'Ravage Magicka',
    concoction: 'Poison',
    description: 'Concentrated poison damages maximum magicka by <mag> points.',
    baseCost: 1,
    magnitude: 2,
    duration: 10,
    valueAt100Skill: 15,
    fixed: 'duration',
  },
  {
    name: 'Ravage Stamina',
    concoction: 'Poison',
    description: 'Concentrated poison damages maximum stamina by <mag> points.',
    baseCost: 1.6,
    magnitude: 2,
    duration: 10,
    valueAt100Skill: 24,
    fixed: 'duration',
  },
  {
    name: 'Regenerate Health',
    concoction: 'Potion',
    description: 'Health regenerates <mag>% faster for <dur> seconds.',
    baseCost: 0.1,
    magnitude: 5,
    duration: 300,
    valueAt100Skill: 177,
    fixed: 'duration',
  },
  {
    name: 'Regenerate Magicka',
    concoction: 'Potion',
    description: 'Magicka regenerates <mag>% faster for <dur> seconds.',
    baseCost: 0.1,
    magnitude: 5,
    duration: 300,
    valueAt100Skill: 177,
    fixed: 'duration',
  },
  {
    name: 'Regenerate Stamina',
    concoction: 'Potion',
    description: 'Stamina regenerates <mag>% faster for <dur> seconds.',
    baseCost: 0.1,
    magnitude: 5,
    duration: 300,
    valueAt100Skill: 177,
    fixed: 'duration',
  },
  {
    name: 'Resist Fire',
    concoction: 'Potion',
    description: 'Resist <mag>% of fire damage for <dur> seconds.',
    baseCost: 0.5,
    magnitude: 3,
    duration: 60,
    valueAt100Skill: 86,
    fixed: 'duration',
  },
  {
    name: 'Resist Frost',
    concoction: 'Potion',
    description: 'Resist <mag>% of frost damage for <dur> seconds.',
    baseCost: 0.5,
    magnitude: 3,
    duration: 60,
    valueAt100Skill: 86,
    fixed: 'duration',
  },
  {
    name: 'Resist Magic',
    concoction: 'Potion',
    description: 'Resist <mag>% of magic for <dur> seconds.',
    baseCost: 1,
    magnitude: 1,
    duration: 60,
    valueAt100Skill: 51,
    fixed: 'duration',
  },
  {
    name: 'Resist Poison',
    concoction: 'Potion',
    description: 'Resist <mag>% of poison for <dur> seconds.',
    baseCost: 0.5,
    magnitude: 4,
    duration: 60,
    valueAt100Skill: 118,
    fixed: 'duration',
  },
  {
    name: 'Resist Shock',
    concoction: 'Potion',
    description: 'Resist <mag>% of shock damage for <dur> seconds.',
    baseCost: 0.5,
    magnitude: 3,
    duration: 60,
    valueAt100Skill: 86,
    fixed: 'duration',
  },
  {
    name: 'Restore Health',
    concoction: 'Potion',
    description: 'Restore <mag> points of Health.',
    baseCost: 0.5,
    magnitude: 5,
    duration: 0,
    valueAt100Skill: 21,
    fixed: 'duration',
  },
  {
    name: 'Restore Magicka',
    concoction: 'Potion',
    description: 'Restore <mag> points of Magicka.',
    baseCost: 0.6,
    magnitude: 5,
    duration: 0,
    valueAt100Skill: 25,
    fixed: 'duration',
  },
  {
    name: 'Restore Stamina',
    concoction: 'Potion',
    description: 'Restore <mag> Stamina.',
    baseCost: 0.6,
    magnitude: 5,
    duration: 0,
    valueAt100Skill: 25,
    fixed: 'duration',
  },
  {
    name: 'Slow',
    concoction: 'Poison',
    description: 'Target moves at 50% speed for <dur> seconds.',
    baseCost: 1,
    magnitude: 50,
    duration: 5,
    valueAt100Skill: 247,
    fixed: 'magnitude',
  },
  {
    name: 'Spell Absorption',
    'DLCs': ['Rare Curios Creation'],
    concoction: 'Potion',
    description:
      'Absorb <mag>% of the magicka from hostile spells for <dur> seconds.',
    baseCost: 1,
    magnitude: 1,
    duration: 1,
    valueAt100Skill: 380,
    fixed: 'N/A',
  },
  {
    name: 'Waterbreathing',
    concoction: 'Potion',
    description: 'Can breathe underwater for <dur> seconds.',
    baseCost: 30,
    magnitude: 0,
    duration: 5,
    valueAt100Skill: 100,
    fixed: 'magnitude',
  },
  {
    name: 'Weakness to Fire',
    concoction: 'Poison',
    description: 'Target is <mag>% weaker to fire damage for <dur> seconds.',
    baseCost: 0.6,
    magnitude: 3,
    duration: 30,
    valueAt100Skill: 48,
    fixed: 'duration',
  },
  {
    name: 'Weakness to Frost',
    concoction: 'Poison',
    description: 'Target is <mag>% weaker to frost damage for <dur> seconds.',
    baseCost: 0.5,
    magnitude: 3,
    duration: 30,
    valueAt100Skill: 40,
    fixed: 'duration',
  },
  {
    name: 'Weakness to Magic',
    concoction: 'Poison',
    description: 'Target is <mag>% weaker to magic for <dur> seconds.',
    baseCost: 1,
    magnitude: 2,
    duration: 30,
    valueAt100Skill: 51,
    fixed: 'duration',
  },
  {
    name: 'Weakness to Poison',
    concoction: 'Poison',
    description: 'Target is <mag>% weaker to poison for <dur> seconds.',
    baseCost: 1,
    magnitude: 2,
    duration: 30,
    valueAt100Skill: 51,
    fixed: 'duration',
  },
  {
    name: 'Weakness to Shock',
    concoction: 'Poison',
    description: 'Target is <mag>% weaker to shock damage for <dur> seconds.',
    baseCost: 0.7,
    magnitude: 3,
    duration: 30,
    valueAt100Skill: 56,
    fixed: 'duration',
  },
];
