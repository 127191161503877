import React, { useState } from 'react';
import * as S from './AutoCompleteInput.style';

const ascBy = getValue => (one, two) => getValue(one) - getValue(two);

export default function AutoCompleteInput({ value, onChange, suggestions }) {
  const [tmpValue = value, setTmpValue] = useState();

  function applyValue(value) {
    onChange(value);
    setTmpValue(void 0);
  }

  const matches = suggestions
    .filter(str => str.toLowerCase().includes(tmpValue.toLowerCase()))
    .sort(ascBy(str => str.toLowerCase().indexOf(tmpValue.toLowerCase())));

  return (
    <S.AutoCompleteInput>
      <input
        value={tmpValue}
        onChange={e => setTmpValue(e.target.value)}
        onBlur={() => applyValue(tmpValue)}
        onKeyPress={e => {
          if (e.key === 'Enter' && matches[0]) {
            applyValue(matches[0]);
            setTimeout(e.target.blur.bind(e.target), 0);
          }
        }}
      />
      <ul>
        {matches.map(suggestion => (
          <li key={suggestion}>
            <button
              onClick={e => {
                applyValue(suggestion);
                e.target.blur();
              }}
            >
              {suggestion}
            </button>
          </li>
        ))}
      </ul>
    </S.AutoCompleteInput>
  );
}
