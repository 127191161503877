function getMagnitude(effect, powerFactor) {
  if (effect.magnitude === 0) {
    return 0;
  }
  if (effect.fixed === 'magnitude') {
    return Math.round(effect.magnitude);
  } else {
    return Math.round(effect.magnitude * powerFactor);
  }
}

function getDuration(effect, powerFactor) {
  if (effect.duration === 0) {
    return 0;
  }
  if (effect.fixed === 'duration') {
    return Math.round(effect.duration);
  } else {
    return Math.round(effect.duration * powerFactor);
  }
}

export function scaleEffect(effect, concoction, getPowerFactor) {
  const powerFactor = getPowerFactor(effect, concoction);
  const magnitude = getMagnitude(effect, powerFactor);
  const magnitudeFactor = magnitude > 0 ? magnitude : 1;
  const duration = getDuration(effect, powerFactor);
  const durationFactor = duration > 0 ? duration / 10 : 1;
  const baseCost = Math.floor(
    effect.baseCost * Math.pow(magnitudeFactor * durationFactor, 1.1)
  );

  return {
    name: effect.name,
    concoction: effect.concoction,
    description: effect.description,
    magnitude,
    duration,
    baseCost,
  };
}
