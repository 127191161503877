import styled from 'styled-components';
import { Table as DefaultTable } from 'styles/Table/Table.style.js';

export const Table = styled(DefaultTable)`
  td:nth-child(2) {
    text-align: center;
  }

  input[type='number'] {
    text-align: right;
  }

  input[type='number'],
  input[type='range'],
  button {
    width: 100%;
  }
`;
