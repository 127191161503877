import * as ls from 'util/localStorage';

export const load = () => {
  const language = ls.getJson('language');

  if (['English', 'French'].includes(language)) {
    return { language };
  } else {
    return { language: 'English' };
  }
};

export const unload = data => {
  ls.setJson('language', data.language);
};
