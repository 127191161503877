import styled from 'styled-components';

const Button = styled.button`
  background-color: transparent;
  border: 1px solid var(--dark-blue);
  color: var(--dark-blue);

  cursor: pointer;
`;

export default Button;

export const IconButton = styled.button`
  background-color: transparent;
  border: 1px solid var(--dark-blue);
  color: var(--dark-blue);
  stroke: var(--dark-blue);

  cursor: pointer;
  padding: 4px 6px;

  svg {
    display: block;
  }
`;
