import { fuseState } from 'pocki2';
import store from 'store';
import { ingredientsForSelectedDLCsStore } from './ingredients';
import { getIngredientCombos } from 'calc/combo';
import { calculateAllPotions } from 'calc/potion';

const simplifyEffects = ingredient => ({
  ...ingredient,
  effects: ingredient.effects.map(effect => effect.name),
});

const allPotions = fuseState({
  ingredientCombos: ingredientsForSelectedDLCsStore
    .map(ingredients => ingredients.map(simplifyEffects))
    .map(ingredients => getIngredientCombos(ingredients)),
  settings: store.map(state => state.settings),
}).map(state => calculateAllPotions(state.ingredientCombos, state.settings));

const removedIngredients = store.map(state => state.removedIngredients);

export const availablePotionsStore = fuseState({
  allPotions,
  removedIngredients,
}).map(state =>
  state.allPotions.filter(
    potion =>
      !potion.ingredients.some(ingredient =>
        state.removedIngredients.includes(ingredient.name)
      )
  )
);
