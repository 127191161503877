import styled from 'styled-components';

export const Fieldset = styled.fieldset`
  display: flex;
  flex-direction: column;
  border-style: dotted;
`;

export const MakeAllFieldsetsSameWidth = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 32px 0;
  grid-gap: 32px;

  ${Fieldset} {
    width: 100%;
  }
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  input[type='checkbox'] {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }

  label > input[type='checkbox'] {
    margin-left: 8px;
  }

  label {
    padding: 10px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    cursor: pointer;
  }
  label:hover {
    background: var(--almost-white);
  }
`;

export const Disclaimer = styled.p`
  padding: 0 32px;
  text-align: center;
  font-style: italic;
`;

export const Beta = styled.span`
  display: inline-block;
  transform: translateY(3px);

  font-size: 0.8em;
  font-weight: 600;
  font-style: italic;

  &:after {
    content: 'BETA';
  }
`;
