import styled from 'styled-components';

export const Dialog = styled.dialog`
  position: fixed;
  top: 20%;

  border-radius: 4px;
  border: 2px solid var(--dark-blue);
  background: var(--white);
`;

export const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;

  background: #0007;
`;
