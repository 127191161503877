import React from 'react';
import * as S from './Locales.style.js';
import { Link } from 'react-router-dom';

import { useStore } from 'pocki2';
import store from 'store';

const localeStore = store.map(state => state.locale);

export default function Locales() {
  const { language } = useStore(localeStore);

  function setLanguage(language) {
    store.setState(state => ({
      locale: { language },
    }));
  }

  return (
    <S.Section>
      <S.MakeAllFieldsetsSameWidth>
        <S.Fieldset name="lang" role="radiogroup">
          <legend>Languages</legend>
          <label htmlFor="English" onClick={() => setLanguage('English')}>
            English{' '}
            <input
              type="radio"
              name="lang"
              value="English"
              checked={language === 'English'}
              onChange={() => {} /* Handled in label.onClick*/}
            />
          </label>
          <label htmlFor="French" onClick={() => setLanguage('French')}>
            French
            <S.Beta />{' '}
            <input
              type="radio"
              name="lang"
              value="French"
              checked={language === 'French'}
              onChange={() => {} /* Handled in label.onClick*/}
            />
          </label>
        </S.Fieldset>
      </S.MakeAllFieldsetsSameWidth>
      <S.Disclaimer>
        *Only partially translated to <strong>French</strong>: Only translates
        names of <strong>ingredients</strong> from{' '}
        <Link to={'/dlc'}>Vanilla Skyrim</Link>. Everything else is still in
        English.
      </S.Disclaimer>
    </S.Section>
  );
}
