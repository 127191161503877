import * as ls from 'util/localStorage';

export const baseSkills = {
  alchemySkillLevel: 15,
  fortifyAlchemyPercent: 0,
  alchemistPerkLevel: 0,
  hasPhysicianPerk: false,
  hasBenefactorPerk: false,
  hasPoisonerPerk: false,
  hasPurityPerk: false,
};

export const load = () => ls.getJson('settings') || baseSkills;
export const unload = data => ls.setJson('settings', data);
