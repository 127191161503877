import styled from 'styled-components';

export const Table = styled.table`
  margin: 0 auto;

  caption {
    font-size: 24px;
    padding: 40px;
  }

  thead td {
    font-weight: bold;

    &.orderable {
      cursor: pointer;
      user-select: none;
      white-space: nowrap;
    }
  }

  td {
    padding: 4px 10px;

    &.number {
      text-align: right;
    }
  }
`;
