import React, { useState, useCallback } from 'react';
import * as S from './Modal.style';
import ClickOutside from 'components/ClickOutside/ClickOutside';

export default function Modal(props) {
  const [isOpen, setIsOpen] = useState(false);

  const open = useCallback(() => setIsOpen(true), []);
  const close = useCallback(() => setIsOpen(false), []);

  if (props.disabled) {
    return props.children;
  }

  return (
    <>
      {isOpen && (
        <S.Background>
          <ClickOutside onClickOutside={close}>
            <S.Dialog open>{props.content({ close })}</S.Dialog>
          </ClickOutside>
        </S.Background>
      )}
      {React.cloneElement(props.children, { onClick: open })}
    </>
  );
}
