import React from 'react';
import * as I from './Input.style.js';
import Modal from 'components/Modal/Modal';
import FilterModalContent from './FilterModalContent';
import filterButton from './filterButton';

import { useStore } from 'pocki2';
import filterStore from './filterStore';
import { ingredientsForSelectedDLCsStore } from 'store/sub/ingredients';

const ingredientNameStore = ingredientsForSelectedDLCsStore.map(ingredients =>
  ingredients.map(ingredient => ingredient.name)
);

export default function FilterIngredients({ filter }) {
  const ingredientNames = useStore(ingredientNameStore);

  return (
    <I.Wrapper>
      <I.Input
        type="text"
        className={filter.ingredients === '' ? 'empty' : 'filled'}
        placeholder="Includes"
        value={filter.ingredients}
        onChange={event =>
          filterStore.setState({ ingredients: event.target.value })
        }
      />
      <Modal
        content={props => (
          <FilterModalContent
            close={props.close}
            thing="Ingredient"
            suggestions={ingredientNames}
            subFilter={filter.ingredients}
            onChange={ingredients => filterStore.setState({ ingredients })}
          />
        )}
      >
        {filterButton}
      </Modal>
    </I.Wrapper>
  );
}
