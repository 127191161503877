import React from 'react';
import { useStore } from 'pocki2';
import * as S from './Skills.style.js';
import Button from 'styles/Button/Button.style.js';

import store from 'store';
import { baseSkills } from 'store/powerSettings';

const settingsStore = store.map(state => state.settings);

const clamp = (min, max) => n => Math.max(min, Math.min(max, n));

export default function Skills() {
  const settings = useStore(settingsStore);

  const getSetter = (property, adjust = v => v) => event => {
    store.setState({
      settings: {
        ...settings,
        [property]: adjust(event.target.value),
      },
    });
  };

  const getToggler = property => () => {
    store.setState({
      settings: {
        ...settings,
        [property]: !settings[property],
      },
    });
  };

  function renderRangeInput(propName, { min = 0, max = 100, step = 1 } = {}) {
    return (
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        value={settings[propName]}
        onChange={getSetter(propName)}
      />
    );
  }
  function renderNumberInput(propName, { min = 0, max = 100, step = 1 } = {}) {
    return (
      <input
        type="number"
        min={min}
        max={max}
        step={step}
        value={settings[propName]}
        onChange={getSetter(propName, clamp(min, max))}
      />
    );
  }
  function renderCheckbox(propName) {
    return (
      <input
        type="checkbox"
        checked={settings[propName]}
        onChange={() => {
          /* handled elsewere */
        }}
      />
    );
  }

  return (
    <S.Table>
      <caption>Alchemy skills</caption>
      <tbody>
        <tr>
          <td />
          <td>
            <Button
              onClick={() => {
                store.setState({ settings: baseSkills });
              }}
            >
              Reset
            </Button>
          </td>
          <td />
        </tr>
        <tr>
          <td>Alchemy Skill Level</td>
          <td>{renderNumberInput('alchemySkillLevel')}</td>
          <td>{renderRangeInput('alchemySkillLevel')}</td>
        </tr>
        <tr>
          <td>Fortify Alchemy Percent</td>
          <td>
            <input
              type="number"
              min={0}
              max={999}
              step={1}
              value={settings['fortifyAlchemyPercent']}
              onChange={getSetter(
                'fortifyAlchemyPercent',
                clamp(0, Number.POSITIVE_INFINITY)
              )}
            />
          </td>
          <td>{renderRangeInput('fortifyAlchemyPercent')}</td>
        </tr>
        <tr>
          <td>Alchemist Perk Level</td>
          <td>{renderNumberInput('alchemistPerkLevel', { max: 5 })}</td>
          <td>{renderRangeInput('alchemistPerkLevel', { max: 5 })}</td>
        </tr>
        <tr>
          <td>Has Physician Perk</td>
          <td onClick={getToggler('hasPhysicianPerk')}>
            {renderCheckbox('hasPhysicianPerk')}
          </td>
        </tr>
        <tr>
          <td>Has Benefactor Perk</td>
          <td onClick={getToggler('hasBenefactorPerk')}>
            {renderCheckbox('hasBenefactorPerk')}
          </td>
        </tr>
        <tr>
          <td>Has Poisoner Perk</td>
          <td onClick={getToggler('hasPoisonerPerk')}>
            {renderCheckbox('hasPoisonerPerk')}
          </td>
        </tr>
        <tr>
          <td>Has Purity Perk</td>
          <td onClick={getToggler('hasPurityPerk')}>
            {renderCheckbox('hasPurityPerk')}
          </td>
        </tr>
      </tbody>
    </S.Table>
  );
}
