import { useState, useCallback, useEffect } from 'react';

export default function useResizeSelector(selector, deps = void 0) {
  const [value, setValue] = useState(selector);
  const memoizedSelector = useCallback(selector, deps);

  useEffect(() => {
    function onResize() {
      setValue(memoizedSelector());
    }

    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [memoizedSelector]);

  return value;
}
