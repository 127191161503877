export default function createLazyMappedStore(parentStore, transformState) {
  let state;
  let listeners = [];
  let ticket = {};

  let lastReceivedTicket;

  function emit() {
    listeners.forEach(notify => notify(state, ticket));
  }

  function updateState(parentState, parentTicket) {
    // Will always have received new state/ticket here
    lastReceivedTicket = parentTicket;
    const nextState = transformState(parentState);
    if (nextState === state) {
      return false;
    }
    state = nextState;
    ticket = {};
    return true;
  }
  function parentStateChanged(parentState, parentTicket) {
    const updated = updateState(parentState, parentTicket);
    if (updated) {
      emit();
    }
  }
  function ensureUpdatedState() {
    parentStore.checkIn(lastReceivedTicket, updateState);
  }

  function getState() {
    ensureUpdatedState();
    return state;
  }
  function getStateAndTicket() {
    ensureUpdatedState();
    return [state, ticket];
  }

  function subscribe(fn) {
    if (listeners.length === 0) {
      // wake
      parentStore.subscribe(parentStateChanged);
    }
    listeners.push(fn);
    return () => unsubscribe(fn);
  }
  function unsubscribe(fn) {
    listeners = listeners.filter(remaining => remaining !== fn);
    if (listeners.length === 0) {
      // sleep
      parentStore.unsubscribe(parentStateChanged);
    }
  }
  function retroSubscribe(fn) {
    ensureUpdatedState();
    fn(state, ticket);
    return subscribe(fn);
  }

  function checkIn(lastTicket, fn) {
    ensureUpdatedState();
    if (lastTicket !== ticket) {
      fn(state, ticket);
    }
  }

  function map(transform) {
    return createLazyMappedStore(
      { subscribe, unsubscribe, checkIn },
      transform
    );
  }

  return {
    getState,
    getStateAndTicket,
    subscribe,
    unsubscribe,
    retroSubscribe,
    map,
  };
}
