import { createStore } from 'pocki2';
import * as powerSettings from './powerSettings';
import * as ingredients from './ingredients';
import * as selectedDLCs from './dlc';
import * as selectedLocale from './locale';

const store = createStore({
  settings: powerSettings.load(),
  removedIngredients: ingredients.load(),
  selectedDLCs: selectedDLCs.load(),
  locale: selectedLocale.load(),
  allPotions: [],
  availablePotions: [],
});

export default store;

window.addEventListener('beforeunload', () => {
  const state = store.getState();

  powerSettings.unload(state.settings);
  ingredients.unload(state.removedIngredients);
  selectedDLCs.unload(state.selectedDLCs);
  selectedLocale.unload(state.locale);
});
