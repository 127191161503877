import styled from 'styled-components';

export const FilterAndOrderMobile = styled.div`
  fieldset:not(.collapsed) + fieldset {
    margin-top: 10px;
  }

  fieldset {
    color: var(--crimson);
    border: 1px solid currentColor;

    legend {
      font-weight: bold;
      padding-inline: 8px;
    }

    legend {
      cursor: pointer;
    }
    legend button {
      color: inherit;

      background: none;
      border: none;
      padding: 0;
    }

    &.collapsed {
      color: var(--faded-yellow);
      max-height: 0;
      overflow: hidden;
      border: none;
      border-top: 1px dotted currentColor;
      padding-bottom: 0;
    }
  }

  fieldset.order {
    label {
      display: block;
      min-height: 1.4em;
    }
  }

  fieldset.filter {
    display: table;

    label {
      display: table-row;
    }
    label > * {
      display: table-cell;
      padding: 4px 0;
    }
    label > * + * {
      padding-left: 10px;
    }
  }
`;
