import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const TabBar = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;

  display: flex;

  li {
    flex-grow: 1;
    display: flex;
  }

  li a {
    text-decoration: none;
  }
  li a,
  li button {
    flex-grow: 1;
    text-align: center;

    padding: 8px 16px;
    @media (max-width: 768px) {
      padding: 8px;
    }

    font: 400 13.3333px Arial;

    border: 0px solid var(--dark-blue);
    border-bottom-width: 1px;
    border-radius: 0;

    &.active  {
      background-color: var(--dark-blue);
      color: var(--white);
    }
    &:not(.active) {
      background-color: var(--white);
      color: var(--dark-blue);
      cursor: pointer;
    }
  }

  li:not(:first-child) a,
  li:not(:first-child) button {
    border-left-width: 1px;
  }
`;

export const Tab = props => (
  <li>
    <button {...props} />
  </li>
);

export const TabLink = props => (
  <li>
    <NavLink {...props} />
  </li>
);

export const TabContent = styled.div``;
