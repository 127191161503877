import store from 'store';
import { fuseState } from 'pocki2';
import _ingredients from 'data/ingredientsWithFullEffects';
import { not } from 'util/logic';

export const ingredientsForSelectedDLCsStore = store
  .map(state => state.selectedDLCs)
  .map(dlcs =>
    _ingredients.filter(ingredient => dlcs.includes(ingredient.dlc || 'Skyrim'))
  );

const isRemovedStore = store
  .map(state => state.removedIngredients)
  .map(removedIngredients => ingredient =>
    removedIngredients.includes(ingredient.name)
  );

const isAvailableStore = isRemovedStore.map(not);

export const availableIngredientsStore = fuseState({
  ingredients: ingredientsForSelectedDLCsStore,
  isAvailable: isAvailableStore,
}).map(state => state.ingredients.filter(state.isAvailable));

export const unavailableIngredientsStore = fuseState({
  ingredients: ingredientsForSelectedDLCsStore,
  isRemoved: isRemovedStore,
}).map(state => state.ingredients.filter(state.isRemoved));

export const taggedIngredientsStore = fuseState({
  ingredients: ingredientsForSelectedDLCsStore,
  isAvailable: isAvailableStore,
}).map(state =>
  state.ingredients.map(ingredient => ({
    ...ingredient,
    isAvailable: state.isAvailable(ingredient),
  }))
);
