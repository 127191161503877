import store from 'store';
import { ingredientsForSelectedDLCsStore } from 'store/sub/ingredients';
import { unique } from 'util/array';

export function removeIngredient(ingredientName) {
  store.setState(state => ({
    removedIngredients: state.removedIngredients.concat(ingredientName),
  }));
}

export function addIngredient(ingredientName) {
  store.setState(state => ({
    removedIngredients: state.removedIngredients.filter(
      string => string !== ingredientName
    ),
  }));
}

export function removeAllIngredients() {
  const names = ingredientsForSelectedDLCsStore.getState().map(i => i.name);
  store.setState(state => ({
    removedIngredients: state.removedIngredients.concat(names).filter(unique),
  }));
}

export function addAllIngredients() {
  const names = ingredientsForSelectedDLCsStore.getState().map(i => i.name);
  store.setState(state => ({
    removedIngredients: state.removedIngredients.filter(i =>
      names.includes(i.name)
    ),
  }));
}
