import * as Sentry from '@sentry/browser';

export function getJson(key) {
  try {
    return [localStorage.getItem(key)]
      .filter(string => string !== null)
      .map(JSON.parse)[0];
  } catch (error) {
    Sentry.captureMessage(
      `Could not parse value of [${key}] from localStorage: ${error.message}`,
      'info'
    );
  }
}

export function setJson(key, value) {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    Sentry.captureMessage(
      `Could not set [${key}] in localStorage: ${error.message}`,
      'info'
    );
  }
}
