import effectMap from 'data/effectMap';
import { createGetPowerFactor } from './power';
import { multiplyEffect } from './multipliers';
import { scaleEffect } from './value';
import { unique, sumTotal, order } from 'util/array';
import { not } from 'util/logic';

function getEffectNames(ingredients) {
  return ingredients
    .flatMap(ingredient => ingredient.effects)
    .filter(not(unique))
    .filter(unique);
}

function getUnscaledEffects(ingredients) {
  return getEffectNames(ingredients)
    .map(name => effectMap[name])
    .map(effect => multiplyEffect(effect, ingredients))
    .sort(order('descending', effect => effect._value));
}
function getEffects(ingredients, getPowerFactor, settings) {
  const effects = getUnscaledEffects(ingredients);
  const best = effects[0];

  return effects
    .filter(
      settings.hasPurityPerk
        ? effect => effect.concoction === best.concoction
        : () => true
    )
    .map(effect => scaleEffect(effect, best.concoction, getPowerFactor))
    .sort(order('descending', effect => effect.baseCost));
}

export function calculateAllPotions(ingredientCombos, settings) {
  const getPowerFactor = createGetPowerFactor(settings);

  return ingredientCombos
    .map(ingredients => {
      const includedEffects = getEffects(ingredients, getPowerFactor, settings);

      const value = includedEffects
        .map(effect => effect.baseCost)
        .reduce(sumTotal);

      const mainEffect = includedEffects[0];

      return {
        id: ingredients.map(ingredient => ingredient.name).join('&'),
        name: `${mainEffect.concoction} of ${mainEffect.name}`,
        ingredients: ingredients,
        effects: includedEffects,
        value: value,
      };
    })
    .sort(order('descending', potion => potion.value));
}
