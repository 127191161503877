import React from 'react';
import * as S from './Hider.style.js';

export default function Hider(props) {
  const { isHidden, show, children } = props;

  return (
    <React.Fragment>
      {isHidden && (
        <S.ShowButton onClick={show}>{props.showButtonText}</S.ShowButton>
      )}
      <S.Shrinkable
        style={
          isHidden
            ? { maxWidth: 0, maxHeight: 0 }
            : { maxWidth: 1000, maxHeight: 3000 }
        }
      >
        {children}
      </S.Shrinkable>
    </React.Fragment>
  );
}
