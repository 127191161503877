import createLazyMappedStore from './createLazyMappedStore';

export default function fuseState(fuseMap) {
  const subStores = Object.values(fuseMap);

  let lastReceivedTickets = subStores.map(() => void 0);

  function produceState() {
    return Object.entries(fuseMap)
      .map(([key, store], index) => {
        const [state, ticket] = store.getStateAndTicket();
        lastReceivedTickets.splice(index, 1, ticket);
        return [key, state];
      })
      .reduce((fs, [key, state]) => {
        fs[key] = state;
        return fs;
      }, {});
  }

  let listener;
  function onChange() {
    listener(produceState(), {});
  }
  function subscribe(fn) {
    listener = fn;
    subStores.forEach(store => store.subscribe(onChange));
  }
  function unsubscribe() {
    subStores.forEach(store => store.unsubscribe(onChange));
    listener = void 0;
  }

  function checkIn(_, fn) {
    const anyChanged = subStores.some(
      (store, index) =>
        store.getStateAndTicket()[1] !== lastReceivedTickets[index]
    );

    if (anyChanged) {
      fn(produceState(), {});
    }
  }

  return createLazyMappedStore(
    { subscribe, unsubscribe, checkIn },
    state => state
  );
}
