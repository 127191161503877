import styled from 'styled-components';

export const Input = styled.input`
  max-width: 88px;
  text-overflow: ellipsis;

  @media (min-width: 666px) {
    max-width: 150px;
  }

  padding: 3px 5px;
  border: 1px solid;

  &::placeholder {
    color: var(--placeholder);
  }

  &.empty {
    background: inherit;
    border-color: var(--light-border);
  }
  &.filled {
    background: var(--almost-white);
    color: inherit;
    font-weight: 600;
  }
`;

export const ValueInput = styled(Input)`
  max-width: 44px;

  @media (min-width: 666px) {
    max-width: 52px;
  }

  text-align: right;
`;

export const Wrapper = styled.span`
  display: inline-block;
  position: relative;

  > input {
    padding-right: 22px;
  }

  > button {
    position: absolute;
    right: 0;
    height: 100%;

    border: none;
    background: transparent;
    cursor: pointer;
    color: var(--dark-blue);

    &:hover {
      color: var(--blackish-blue);
    }
  }
`;
