import styled, { keyframes } from 'styled-components';
import Button from 'styles/Button/Button.style.js';

const appear = keyframes`
  from {
    transform: rotate(-90deg) translateX(-100%) scale(0);
  }
  to {
    transform: rotate(-90deg) translateX(-100%) scale(1);
  }
`;

export const ShowButton = styled(Button)`
  @media (max-width: 900px) {
    /* Mobile */
    margin: auto;
    transform: translateY(60px);
  }
  @media (min-width: 901px) {
    /* PC */
    position: absolute;
    top: 140px;
    left: 10px;
    transform-origin: top left;

    animation-name: ${appear};
    animation-delay: 250ms;
    animation-duration: 100ms;
    animation-fill-mode: both;
  }
`;

export const Shrinkable = styled.div`
  margin: 0 auto;
  transition: max-width 250ms ease, max-height 250ms ease;
  overflow: hidden;
`;
