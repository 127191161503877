import React from 'react';
import AutoCompleteInput from 'components/AutoCompleteInput/AutoCompleteInput';

function Options({ state, update, enableMagnitudeSorting }) {
  return (
    <select
      value={[
        state.omit && 'omit',
        state.exact && 'exact',
        state.magnitudeAsc && 'magnitudeAsc',
        state.magnitudeDesc && 'magnitudeDesc',
      ]
        .filter(Boolean)
        .join(' ')}
      onChange={e =>
        update({
          omit: e.target.value.includes('omit'),
          exact: e.target.value.includes('exact'),
          magnitudeAsc: e.target.value === 'magnitudeAsc',
          magnitudeDesc: e.target.value === 'magnitudeDesc',
        })
      }
    >
      <option value="">Must include</option>
      <option value="omit">Must not include</option>
      <option value="exact">Must be exactly</option>
      <option value="omit exact">Must not be exactly</option>
      {enableMagnitudeSorting && (
        <>
          <option value="magnitudeDesc">Maximize</option>
          <option value="magnitudeAsc">Minimize</option>
        </>
      )}
    </select>
  );
}

function String({ state, update, suggestions }) {
  return (
    <AutoCompleteInput
      value={state.cleanString}
      onChange={cleanString => update({ cleanString })}
      suggestions={suggestions}
    />
  );
}

function Segment(props) {
  const { segment } = props;

  const state = {
    omit: segment[0] === '-',
    exact: segment.match(/!|"/g),
    magnitudeAsc: segment.match(/--$/),
    magnitudeDesc: segment.match(/\+\+$/),
    cleanString: segment.replace(/^-|!|"|\+\+$|--$/g, ''),
  };

  function update({
    omit = state.omit,
    exact = state.exact,
    magnitudeAsc = state.magnitudeAsc,
    magnitudeDesc = state.magnitudeDesc,
    cleanString = state.cleanString,
  }) {
    props.setSegment(
      [
        omit && '-',
        exact && '"',
        cleanString,
        exact && '"',
        magnitudeAsc && '--',
        magnitudeDesc && '++',
      ]
        .filter(Boolean)
        .join('')
    );
  }

  return (
    <tr>
      <td>
        <Options
          state={state}
          update={update}
          enableMagnitudeSorting={props.enableMagnitudeSorting}
        />
      </td>
      <td>
        <String state={state} update={update} suggestions={props.suggestions} />
      </td>
      {props.onRemove && (
        <td>
          <button onClick={props.onRemove}>-</button>
        </td>
      )}
    </tr>
  );
}

export default function FilterModalContent(props) {
  const segments = props.subFilter
    .split(',')
    .map(s => s.trim())
    .filter(Boolean)
    .concat(['']);

  function remove(index) {
    props.onChange(segments.filter((_, i) => i !== index).join(', '));
  }
  function replace(newSegment, index) {
    props.onChange(
      segments
        .map((s, i) => (i === index ? newSegment : s))
        .join(', ')
        .replace(/(, )$/, '' /* Remove trailing comma */)
    );
  }

  const { canHaveMultiple = true, enableMagnitudeSorting = false } = props;

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>Options</th>
            <th>{props.thing}</th>
          </tr>
        </thead>

        <tbody>
          {segments
            .slice(0, !canHaveMultiple ? 1 : void 0)
            .map((segment, index) => (
              <Segment
                key={index}
                segment={segment}
                setSegment={newSegment => replace(newSegment, index)}
                suggestions={props.suggestions}
                onRemove={
                  index === segments.length - 1 ? void 0 : () => remove(index)
                }
                enableMagnitudeSorting={enableMagnitudeSorting}
              />
            ))}
        </tbody>
      </table>
    </div>
  );
}
