import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  grid-gap: 36px;

  position: relative;

  @media (max-width: 1100px) {
    flex-direction: column;
  }
  @media (min-width: 1101px) {
    table {
      max-width: 60vw;
    }
  }
`;

export const PreventShrink = styled.div`
  margin: 0 auto;
  min-height: calc(100vh + 140px);
`;
