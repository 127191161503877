import React from 'react';
import * as S from 'styles/Table/Table.style.js';
import Button, { IconButton } from 'styles/Button/Button.style.js';
import Effect from 'styles/Effect/Effect.style.js';
import CrossIcon from 'icons/CrossIcon';

import { useStore } from 'pocki2';
import { localeNameStore } from 'store/sub/localeName';
import { availableIngredientsStore } from 'store/sub/ingredients';
import {
  removeIngredient,
  removeAllIngredients,
} from 'store/actions/ingredients';
import { useOrder, indicateOrder, orderBy } from 'util/ordering';

export default function AvailableIngredients() {
  const { localeName } = useStore(localeNameStore);
  const [order, toggleOrderOf] = useOrder();
  const availableIngredients = useStore(availableIngredientsStore);

  if (availableIngredients.length === 0) {
    return (
      <S.Table>
        <caption>No Available Ingredients</caption>
      </S.Table>
    );
  }

  const orderedIngredients = order
    ? availableIngredients.slice().sort(orderBy(order))
    : availableIngredients;

  return (
    <S.Table>
      <caption>
        Available Ingredients
        <br />
        <Button onClick={removeAllIngredients}>Remove all</Button>
      </caption>
      <thead>
        <tr>
          <td />
          <td
            className="orderable"
            onClick={() => toggleOrderOf('name', 'string')}
          >
            Name{indicateOrder(order, 'name')}
          </td>
          <td
            className="orderable number"
            onClick={() => toggleOrderOf('value', 'number')}
          >
            Value{indicateOrder(order, 'value')}
          </td>
          <td>Effects</td>
        </tr>
      </thead>
      <tbody>
        {orderedIngredients.map(ingredient => (
          <tr key={ingredient.name}>
            <td>
              <IconButton onClick={() => removeIngredient(ingredient.name)}>
                <span role="img" aria-label="Remove">
                  <CrossIcon />
                </span>
              </IconButton>
            </td>
            <td>{localeName(ingredient)}</td>
            <td className="number">{ingredient.value}</td>
            <td>
              {ingredient.effects.map(effect => (
                <Effect key={effect.name} className={effect.concoction}>
                  {effect.name}
                </Effect>
              ))}
            </td>
          </tr>
        ))}
      </tbody>
    </S.Table>
  );
}
