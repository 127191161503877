import React, { useState } from 'react';
import * as S from './FilterAndOrderMobile.style';

import FilterName from 'routes/Potions/shared/filter/FilterName';
import FilterValue from 'routes/Potions/shared/filter/FilterValue';
import FilterIngredients from 'routes/Potions/shared/filter/FilterIngredients';
import FilterEffects from 'routes/Potions/shared/filter/FilterEffects';

import { indicateOrder } from 'util/ordering';

export default function FilterAndOrderMobile({ filter, order, toggleOrderOf }) {
  const [filterIsOpen, setFilterIsOpen] = useState(false);
  const [orderIsOpen, setOrderIsOpen] = useState(false);

  const orderSpacetaker = <span style={{ visibility: 'hidden' }}>▲</span>;

  return (
    <S.FilterAndOrderMobile>
      <fieldset
        name="Filter"
        className={['filter', !filterIsOpen && 'collapsed']
          .filter(Boolean)
          .join(' ')}
      >
        <legend onClick={() => setFilterIsOpen(o => !o)}>
          Filter{' '}
          <button
            aria-hidden={true /* This button only toggles visual appearance */}
          >
            {filterIsOpen ? '×' : '+'}
          </button>
        </legend>

        {filterIsOpen && (
          <>
            <label>
              <span>Name</span>
              <span>
                <FilterName filter={filter} />
              </span>
            </label>
            <label>
              <span>Value</span>
              <span>
                <FilterValue filter={filter} />
              </span>
            </label>
            <label>
              <span>Ingredients</span>
              <span>
                <FilterIngredients filter={filter} />
              </span>
            </label>
            <label>
              <span>Effects</span>
              <span>
                <FilterEffects filter={filter} />
              </span>
            </label>
          </>
        )}
      </fieldset>

      <fieldset
        name="Order"
        className={['order', !orderIsOpen && 'collapsed']
          .filter(Boolean)
          .join(' ')}
      >
        <legend onClick={() => setOrderIsOpen(o => !o)}>
          Sort{' '}
          <button
            aria-hidden={true /* This button only toggles visual appearance */}
          >
            {orderIsOpen ? '×' : '+'}
          </button>
        </legend>

        {orderIsOpen && (
          <>
            <label>
              <input
                type="radio"
                className="visually-hidden"
                checked={order ? order.key === 'name' : false}
                onChange={() => {}}
                onClick={() => toggleOrderOf('name', 'string')}
              />
              {indicateOrder(order, 'name') || orderSpacetaker} Name
            </label>
            <label>
              <input
                type="radio"
                className="visually-hidden"
                checked={order ? order.key === 'value' : false}
                onChange={() => {}}
                onClick={() => toggleOrderOf('value', 'number')}
              />
              {indicateOrder(order, 'value') || orderSpacetaker} Value
            </label>
            <label>
              <input
                type="radio"
                className="visually-hidden"
                checked={order ? order.key === 'ingredientCount' : false}
                onChange={() => {}}
                onClick={() =>
                  toggleOrderOf(
                    'ingredientCount',
                    'number',
                    potion => potion.ingredients.length
                  )
                }
              />
              {indicateOrder(order, 'ingredientCount') || orderSpacetaker}{' '}
              Ingredients
            </label>
            <label>
              <input
                type="radio"
                className="visually-hidden"
                checked={order ? order.key === 'effectCount' : false}
                onChange={() => {}}
                onClick={() =>
                  toggleOrderOf(
                    'effectCount',
                    'number',
                    potion => potion.effects.length
                  )
                }
              />
              {indicateOrder(order, 'effectCount') || orderSpacetaker} Effects
            </label>
          </>
        )}
      </fieldset>
    </S.FilterAndOrderMobile>
  );
}
