import React from 'react';
import * as I from './Input.style.js';
import Modal from 'components/Modal/Modal';
import FilterModalContent from './FilterModalContent';
import filterButton from './filterButton';

import { useStore } from 'pocki2';
import filterStore from './filterStore';
import { effectsForSelectedDLCsStore } from 'routes/Effects/Effects';

const concoctionNameStore = effectsForSelectedDLCsStore.map(effects =>
  effects.map(effect => `${effect.concoction} of ${effect.name}`)
);

export default function FilterName({ filter }) {
  const concoctionNames = useStore(concoctionNameStore);

  return (
    <I.Wrapper>
      <I.Input
        type="text"
        className={filter.name === '' ? 'empty' : 'filled'}
        placeholder="Includes"
        value={filter.name}
        onChange={event => filterStore.setState({ name: event.target.value })}
      />
      <Modal
        content={props => (
          <FilterModalContent
            close={props.close}
            thing="Concoction"
            canHaveMultiple={false}
            suggestions={concoctionNames}
            subFilter={filter.name}
            onChange={name => filterStore.setState({ name })}
          />
        )}
      >
        {filterButton}
      </Modal>
    </I.Wrapper>
  );
}
