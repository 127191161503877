import { createStore } from 'pocki2';

export default createStore({
  name: '',
  value: '',
  ingredients: '',
  effects: '',
});

const sortRegex = /\+\+$|--$/;

// Utils
function getNumberChecker(filterString) {
  if (!filterString.match(/\d/)) {
    return () => true;
  }
  return filterString
    .split(',')
    .map(s => s.trim())
    .map(s => [s.replace(/<|>|=/g, ''), s[0] === '<'])
    .filter(([s]) => s !== '')
    .map(([s, less]) => [Number(s), less])
    .filter(([s]) => !Number.isNaN(s))
    .map(([n, less]) => {
      const isLess = number => number < n;
      const isMore = number => number > n;
      return less ? isLess : isMore;
    })
    .reduce(
      (a, b) => number => a(number) && b(number),
      () => true
    );
}

function getStringsChecker(filterString) {
  if (!filterString.match(/[a-zA-Z]/)) {
    return () => true;
  }
  return filterString
    .toLowerCase()
    .split(',')
    .map(s => s.trim())
    .filter(s => !s.match(sortRegex))
    .map(s => [s.replace(/^-|!|"/g, ''), s[0] === '-', s.match(/!|"/g)])
    .filter(([s]) => s !== '')
    .map(([s, omit, exact]) => [
      omit ? b => !b : b => b,
      exact
        ? strings => strings.includes(s)
        : strings => strings.some(string => string.includes(s)),
    ])
    .map(([f, g]) => strings => f(g(strings)))
    .reduce(
      (a, b) => strings => a(strings) && b(strings),
      () => true
    );
}

export const byFilterState = filterStoreState => {
  const state = filterStoreState;

  const valueOk = getNumberChecker(state.value);

  const nameOk = getStringsChecker(state.name);
  const ingredientsOk = getStringsChecker(state.ingredients);
  const effectsOk = getStringsChecker(state.effects);

  const toLcName = thing => thing.name.toLowerCase();

  return potion => {
    return (
      valueOk(potion.value) &&
      nameOk([potion].map(toLcName)) &&
      ingredientsOk(potion.ingredients.map(toLcName)) &&
      effectsOk(potion.effects.map(toLcName))
    );
  };
};

// Sorting
const ascBy = fn => (one, two) => fn(one) - fn(two);
const descBy = fn => (one, two) => fn(two) - fn(one);

export const getEffectSorter = filterStoreState => {
  const str = filterStoreState.effects;

  if (!str) {
    return;
  }

  const comparators = str
    .toLowerCase()
    .split(',')
    .map(s => s.trim())
    .filter(s => s.match(sortRegex))
    .map(s => [
      s.replace(sortRegex, '').trim(),
      s.match(/\+\+/) ? descBy : ascBy,
    ])
    .filter(([name]) => name !== '')
    .map(([name, by]) =>
      by(potion => {
        const effect = potion.effects.find(effect =>
          effect.name.toLowerCase().includes(name)
        );
        return effect ? effect.baseCost : 0;
      })
    );

  if (comparators.length === 0) {
    return;
  }

  return function sort(one, two) {
    for (const compare of comparators) {
      const diff = compare(one, two);
      if (diff !== 0) {
        return diff;
      }
    }
    return 0;
  };
};
