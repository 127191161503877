import styled from 'styled-components';

export const AutoCompleteInput = styled.div`
  position: relative;

  ul {
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 100%;
    z-index: 1;

    margin: 0;
    padding: 0;

    max-height: 300px;
    overflow-y: scroll;

    background: var(--white);

    li {
      display: block;

      button {
        min-width: 100%;
        text-align: left;
        padding: 8px 8px;
        white-space: nowrap;

        background: transparent;
        border: none;
        cursor: pointer;

        color: var(--dark-blue);

        &:hover {
          background: var(--almost-white);
        }
      }
    }

    display: none;
  }

  &:focus-within ul {
    display: initial;
  }
`;
