import styled from 'styled-components';

export const Main = styled.main`
  margin: 0 10px;

  h1 {
    font-size: 24px;
    font-weight: 400;
    margin: 40px 0;
  }

  ul {
    list-style-type: none;
    padding-inline-start: 0rem;
  }
`;
