import React from 'react';
import * as S from 'components/PillToggler/PillToggler.js';

import { useStore } from 'pocki2';
import store from 'store';
import { availableDLCs } from 'store/dlc';
import { toggle } from 'util/array';

function toggleDLC(dlc) {
  store.setState(state => ({
    selectedDLCs: toggle(state.selectedDLCs, dlc),
  }));
}

const selectedDLCsStore = store.map(state => state.selectedDLCs);

export default function DLCPills() {
  const selectedDLCs = useStore(selectedDLCsStore);

  return (
    <S.List style={{ marginBottom: '4px' }}>
      {availableDLCs.map(dlc => (
        <S.PillButton
          key={dlc}
          onClick={() => toggleDLC(dlc)}
          className={[
            'secondary',
            selectedDLCs.includes(dlc) ? 'solid' : '',
          ].join(' ')}
        >
          {dlc.replace(' Creation', '')}
        </S.PillButton>
      ))}
      <S.PillLink to="/dlc" className="secondary solid">
        <span role="img" aria-label="link">
          🔗
        </span>
      </S.PillLink>
    </S.List>
  );
}
