import React, { useState } from 'react';
import * as S from './PotionItem.style';
import Effect from 'styles/Effect/Effect.style.js';
import { IconButton } from 'styles/Button/Button.style.js';
import CrossIcon from 'icons/CrossIcon';

import { useStore } from 'pocki2';
import { localeNameStore } from 'store/sub/localeName';
import { removeIngredient } from 'store/actions/ingredients';

function useSelectedItem() {
  const [item, setItem] = useState(void 0);
  function unsetItem(someItem) {
    // If the item to unset is no longer selected, leave it as is
    setItem(item => (item === someItem ? void 0 : item));
  }
  return [item, setItem, unsetItem];
}

export default function PotionItem({ potion }) {
  const { localeName } = useStore(localeNameStore);

  const [
    selectedIngredient,
    selectIngredient,
    deselectIngredient,
  ] = useSelectedItem();
  const [selectedEffect, selectEffect, deselectEffect] = useSelectedItem();

  const isGivenBySelectedIngredient = selectedIngredient
    ? effect => selectedIngredient.effects.includes(effect.name)
    : () => false;

  const hasSelectedEffect = selectedEffect
    ? ingredient => ingredient.effects.includes(selectedEffect.name)
    : () => false;

  return (
    <li>
      <S.Potion>
        <h2>
          {potion.name}
          <span className="detail">
            {' '}
            <span role="img" aria-label="value">
              💰
            </span>
            {potion.value}
          </span>
        </h2>
        <section className="ingredients">
          <h3>Ingredients</h3>
          <ul>
            {potion.ingredients.map(ingredient => (
              <li key={ingredient.name}>
                <IconButton onClick={() => removeIngredient(ingredient.name)}>
                  <span role="img" aria-label="Remove">
                    <CrossIcon />
                  </span>
                </IconButton>
                &ensp;
                <S.Ingredient
                  title={`${localeName(
                    ingredient
                  )}:\n* ${ingredient.effects.join('\n* ')}`}
                  onMouseEnter={() => selectIngredient(ingredient)}
                  onMouseLeave={() => deselectIngredient(ingredient)}
                  className={hasSelectedEffect(ingredient) ? 'highlighted' : ''}
                >
                  {localeName(ingredient)}
                </S.Ingredient>
              </li>
            ))}
          </ul>
        </section>
        <section className="effects">
          <h3>Effects</h3>
          <ul>
            {potion.effects.map(effect => (
              <li key={effect.name}>
                <Effect
                  title={[
                    `${effect.name}:`,
                    `―${effect.description
                      .replace('<dur>', effect.duration)
                      .replace('<mag>', effect.magnitude)}`,
                    `* ${effect.baseCost} gold`,
                  ].join('\n')}
                  onMouseEnter={() => selectEffect(effect)}
                  onMouseLeave={() => deselectEffect(effect)}
                  className={[
                    effect.concoction,
                    'hoverable',
                    isGivenBySelectedIngredient(effect) ? 'highlighted' : '',
                  ].join(' ')}
                >
                  <h4>
                    {effect.name}
                    <span className="detail">
                      {' '}
                      <span role="img" aria-label="value">
                        💰
                      </span>
                      {effect.baseCost}
                    </span>
                  </h4>
                  <p className="description">
                    {effect.description
                      .replace('<dur>', effect.duration)
                      .replace('<mag>', effect.magnitude)}
                  </p>
                </Effect>
              </li>
            ))}
          </ul>
        </section>
      </S.Potion>
    </li>
  );
}
