import React from 'react';
import * as S from './PotionListMobile.style';
import FilterAndOrderMobile from './FilterAndOrderMobile/FilterAndOrderMobile';
import PotionItem from './PotionItem/PotionItem';

import useDeferredStore from 'pocki2/useDeferredStore';
import { useStore } from 'pocki2';
import filterStore, {
  byFilterState,
  getEffectSorter,
} from 'routes/Potions/shared/filter/filterStore';
import { availablePotionsStore } from 'store/sub/potions';
import { useOrder, orderBy } from 'util/ordering';

export default function PotionListmobile() {
  const [order, toggleOrderOf] = useOrder({
    key: 'value',
    direction: 'descending',
  });
  const availablePotions =
    useDeferredStore(availablePotionsStore, { timeoutMs: 100 }) || 'pending';

  const filter = useStore(filterStore);

  if (availablePotions === 'pending') {
    return (
      <S.Main>
        <h1>Available Potions (computing...)</h1>
      </S.Main>
    );
  }

  let orderedPotions = order
    ? availablePotions.slice().sort(orderBy(order))
    : availablePotions;

  const effectSorter = getEffectSorter(filter);
  if (effectSorter) {
    orderedPotions = orderedPotions.sort(effectSorter);
  }

  const displayedPotions = orderedPotions
    .filter(byFilterState(filter))
    .slice(0, 10);

  return (
    <S.Main>
      <h1>
        Available Potions ({displayedPotions.length} of{' '}
        {availablePotions.length})
      </h1>
      <FilterAndOrderMobile
        filter={filter}
        order={order}
        toggleOrderOf={toggleOrderOf}
      />
      <ul>
        {displayedPotions.map(potion => (
          <PotionItem key={potion.id} potion={potion} />
        ))}
      </ul>
    </S.Main>
  );
}
