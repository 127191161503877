import { useEffect } from 'react';

export default function useClickOutside(element, onClickOutside) {
  useEffect(() => {
    if (!element) return;

    const onClick = (event: Event) => {
      const isMounted = document.body.contains(event.target);
      const isInside = element.contains(event.target);
      const isOutside = isMounted && !isInside;
      if (isOutside) {
        onClickOutside(event);
      }
    };

    document.addEventListener('click', onClick);
    document.addEventListener('contextmenu', onClick);
    return () => {
      document.removeEventListener('click', onClick);
      document.removeEventListener('contextmenu', onClick);
    };
  }, [element, onClickOutside]);
}
