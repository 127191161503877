import styled from 'styled-components';
export { Ingredient } from 'routes/Potions/PotionTable/PotionRow/PotionRow.style';

export const Potion = styled.article`
  h3,
  h4,
  p {
    margin: 5px 0;
  }

  h2 span.detail,
  h4 span.detail {
    font-weight: 400;
    font-style: italic;
    white-space: nowrap;
  }

  h2 {
    font-size: 20px;
    margin: 24px 0 12px;
  }
  h3 {
    margin: 6px 0 2px;
    font-size: 16px;
    font-weight: 400;
    font-variant: small-caps;
    font-style: italic;
    opacity: 0.7;
  }
  h4 {
    font-size: 16px;
    font-weight: 400;
  }

  section ul li {
    margin-left: 6px;
  }

  section.ingredients ul li {
    display: flex;
    align-items: center;
  }

  section.effects h3 {
    margin: 12px 0 -6px;
  }

  section.effects p.description {
    font-size: 12px;
    margin: 2px 8px;
  }
`;
