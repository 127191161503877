const maximizeBy = fn => (one, two) => {
  return fn(one) > fn(two) ? one : two;
};

const ggg = effectName => prop => ingredient => {
  return ingredient.multipliers && ingredient.multipliers[effectName]
    ? ingredient.multipliers[effectName][prop]
    : 1;
};

export function multiplyEffect(effect, ingredients) {
  const gg = ggg(effect.name);
  const ingredient = ingredients.reduce(maximizeBy(gg('value')));

  const magnitude = gg('magnitude')(ingredient) * effect.magnitude;
  const duration = gg('duration')(ingredient) * effect.duration;
  const _value = gg('value')(ingredient) * effect.baseCost;

  return {
    name: effect.name,
    concoction: effect.concoction,
    description: effect.description,
    baseCost: effect.baseCost,
    fixed: effect.fixed,
    magnitude,
    duration,
    _value,
    // _value is only used to determine concoction.
    // Actual value will take concoction and power factor into acctount.
  };
}
