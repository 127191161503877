import styled from 'styled-components';

const Effect = styled.div`
  &.Potion {
    color: var(--green);
  }
  &.Poison {
    color: var(--crimson);
  }

  &.hoverable {
    padding: 2px 6px;
    margin: 1px;
    border-radius: 8px;
    transform: translateX(-6px);
    transition: color 100ms ease, background-color 100ms ease;

    &:hover,
    &.highlighted {
      .Potion& {
        background-color: var(--dark-blue);
        color: #85be8b;
        color: var(--bright-yellow);
      }
      .Poison& {
        background-color: var(--dark-blue);
        color: var(--faded-yellow);
      }
    }
  }
`;

export default Effect;
