import React from 'react';

export default function CheckIcon() {
  return (
    <svg
      width="8"
      height="8"
      fill="none"
      strokeWidth="3"
      strokeLinecap="round"
      viewBox="0 0 16 16"
    >
      <path d="M1,1 L15,15 M1,15 L15,1" />
    </svg>
  );
}
