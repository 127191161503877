export const unique = (item, index, array) => array.indexOf(item) === index;

export const hasIntersection = (one, two) =>
  one.some(item => two.includes(item));

export const containsAllOf = one => two =>
  one.every(item => two.includes(item));

export const intersection = (one, two) => one.filter(x => two.includes(x));

export const subtract = (one, two) => one.filter(a => !two.includes(a));

export const inverseIntersection = (one, two) =>
  subtract(one, two).concat(subtract(two, one));

export const union = (...args) => args.flat().filter(unique);

export function getAllCombinations(array, count) {
  if (count === 1) {
    return array.map(item => [item]);
  }

  return array.reduce((combinations, item, index) => {
    const newCombinationsWithItem = getAllCombinations(
      array.slice(index + 1),
      count - 1
    ).map(combination => [item].concat(combination));

    return combinations.concat(newCombinationsWithItem);
  }, []);
}

export const sumTotal = (sum, next) => sum + next;
export const multiply = (product, next) => product * next;

export const order = (direction, getValue = v => v) => {
  return {
    ascending: (one, two) => getValue(one) - getValue(two),
    descending: (one, two) => getValue(two) - getValue(one),
  }[direction];
};

export function toggle(array, item) {
  return array.includes(item)
    ? array.filter(keep => keep !== item)
    : array.concat([item]);
}
