function innermost(f, fnE, fnEP) {
  return (e, p) => f * fnE(e) * fnEP(e, p);
}

function inner(a, b, c, d, e) {
  return innermost(4 * a * b * c, d, e);
}

function createGetPhysicianScale(hasPhysicianPerk) {
  const physicianEffects = [
    'Restore Health',
    'Restore Magicka',
    'Restore Stamina',
  ];

  return hasPhysicianPerk
    ? effect => (physicianEffects.includes(effect.name) ? 1.25 : 1)
    : () => 1;
}

function createGetLast(hasBenefactorPerk, hasPoisonerPerk) {
  return {
    'false,false': () => 1,
    'true,false': (effect, concoction) =>
      concoction === 'Potion' && effect.concoction === 'Potion' ? 1.25 : 1,
    'false,true': (effect, concoction) =>
      concoction === 'Poison' && effect.concoction === 'Poison' ? 1.25 : 1,
    'true,true': (effect, concoction) =>
      concoction === effect.concoction ? 1.25 : 1,
  }[[hasBenefactorPerk, hasPoisonerPerk].toString()];
}

export function createGetPowerFactor({
  alchemySkillLevel = 15,
  fortifyAlchemyPercent = 0,
  alchemistPerkLevel = 0,
  hasPhysicianPerk = false,
  hasBenefactorPerk = false,
  hasPoisonerPerk = false,
}) {
  return inner(
    1 + (0.5 * alchemySkillLevel) / 100,
    1 + fortifyAlchemyPercent / 100,
    1 + alchemistPerkLevel / 5,
    createGetPhysicianScale(hasPhysicianPerk),
    createGetLast(hasBenefactorPerk, hasPoisonerPerk)
  );
}
