import styled from 'styled-components';

export const Ingredient = styled.span`
  display: inline-block;

  padding: 2px 4px;
  margin: 1px;
  border-radius: 6px;

  color: var(--dark-blue);
  transition: color 100ms ease, background-color 100ms ease;

  &:hover,
  &.highlighted {
    background-color: var(--dark-blue);
    color: #cde3cf;
  }
`;

export const IngredientLine = styled.div`
  @media (min-width: 700px) {
    white-space: nowrap;
  }

  display: flex;
  align-items: center;
`;
