import React from 'react';
import * as S from 'styles/Table/Table.style.js';
import FakeContent from 'components/FakeContent/FakeContent';
import PotionRow from './PotionRow/PotionRow';
import FilterRow from './FilterRow/FilterRow';

import useDeferredStore from 'pocki2/useDeferredStore';
import { useStore } from 'pocki2';
import filterStore, {
  byFilterState,
  getEffectSorter,
} from 'routes/Potions/shared/filter/filterStore';
import { availablePotionsStore } from 'store/sub/potions';
import { useOrder, indicateOrder, orderBy } from 'util/ordering';

function TitleRow({ order, toggleOrderOf }) {
  return (
    <tr>
      <td className="orderable" onClick={() => toggleOrderOf('name', 'string')}>
        Name{indicateOrder(order, 'name')}
      </td>
      <td
        className="orderable number"
        onClick={() => toggleOrderOf('value', 'number')}
      >
        Value{indicateOrder(order, 'value')}
      </td>
      <td
        className="orderable"
        onClick={() =>
          toggleOrderOf(
            'ingredientCount',
            'number',
            potion => potion.ingredients.length
          )
        }
      >
        Ingredients{indicateOrder(order, 'ingredientCount')}
      </td>
      <td
        className="orderable"
        onClick={() =>
          toggleOrderOf(
            'effectCount',
            'number',
            potion => potion.effects.length
          )
        }
      >
        Effects{indicateOrder(order, 'effectCount')}
      </td>
    </tr>
  );
}

export default function PotionTable() {
  const [order, toggleOrderOf] = useOrder({
    key: 'value',
    direction: 'descending',
  });
  const availablePotions =
    useDeferredStore(availablePotionsStore, { timeoutMs: 100 }) || 'pending';

  const filter = useStore(filterStore);

  if (availablePotions === 'pending') {
    return (
      <S.Table>
        <caption>Available Potions (computing...)</caption>
        <thead>
          <TitleRow order={order} toggleOrderOf={toggleOrderOf} />
          <FilterRow filter={filter} />
        </thead>
        <tbody>
          {Array(50)
            .fill()
            .map((_, i) => (
              <tr key={i}>
                {Array(4)
                  .fill()
                  .map((_, j) => (
                    <td key={j}>
                      <FakeContent />
                    </td>
                  ))}
              </tr>
            ))}
        </tbody>
      </S.Table>
    );
  }

  let orderedPotions = order
    ? availablePotions.slice().sort(orderBy(order))
    : availablePotions;

  const effectSorter = getEffectSorter(filter);
  if (effectSorter) {
    orderedPotions = orderedPotions.sort(effectSorter);
  }

  const displayedPotions = orderedPotions
    .filter(byFilterState(filter))
    .slice(0, 50);

  return (
    <S.Table>
      <caption>
        Available Potions ({displayedPotions.length} of{' '}
        {availablePotions.length})
      </caption>
      <thead>
        <TitleRow order={order} toggleOrderOf={toggleOrderOf} />
        <FilterRow filter={filter} />
      </thead>
      <tbody>
        {displayedPotions.map(potion => (
          <PotionRow key={potion.id} potion={potion} />
        ))}
      </tbody>
    </S.Table>
  );
}
