import React, { useState } from 'react';
import * as S from 'components/PillToggler/PillToggler.js';
import Button from 'styles/Button/Button.style.js';
import Hider from 'components/Hider/Hider';
import IngredientPills from './IngredientPills';
import DLCPills from './DLCPills';

import {
  addAllIngredients,
  removeAllIngredients,
} from 'store/actions/ingredients';

export default function IngredientToggler() {
  const [isHidden, setIsHidden] = useState(window.innerWidth <= 900);

  return (
    <Hider
      isHidden={isHidden}
      show={() => setIsHidden(false)}
      showButtonText="Show overview of ingredients"
    >
      <S.Container>
        <S.Title>Overview of ingredients</S.Title>
        <S.Options>
          <Button onClick={addAllIngredients}>Add all</Button>
          <Button onClick={removeAllIngredients}>Remove all</Button>
          <Button onClick={() => setIsHidden(true)}>Hide</Button>
        </S.Options>
        <DLCPills />
        <IngredientPills />
      </S.Container>
    </Hider>
  );
}
