import store from 'store';

export const localeNameStore = store
  .map(state => state.locale)
  .map(({ language }) => {
    function localeName(ingredient) {
      if (!ingredient) {
        return '';
      }
      return (language === 'French' && ingredient.nameFr) || ingredient.name;
    }
    // Return object instead of function.
    // Returning function breaks useStore(localeNameStore), since for
    // setState(fn), the function is interpreted as a state mapper.
    return { localeName };
  });
