import { useRef, useState, useEffect } from 'react';

export default function useDeferredStore(store, { timeoutMs }) {
  const ref = useRef({ isFirstPass: true, timeoutMs });

  const [state, setState] = useState(void 0);

  useEffect(() => {
    if (ref.current.isFirstPass) {
      ref.current.isFirstPass = false;
      const timeout = setTimeout(
        () => store.retroSubscribe(setState),
        ref.current.timeoutMs
      );
      return () => {
        clearTimeout(timeout);
        store.unsubscribe(setState);
      };
    } else {
      return store.subscribe(setState);
    }
  }, [store]);

  return state;
}
