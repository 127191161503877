import React, { useState } from 'react';
import useClickOutside from './useClickOutside';

export default function ClickOutside(props) {
  const [node, setNode] = useState(null);
  useClickOutside(node, props.onClickOutside);

  return React.cloneElement(props.children, {
    ref: setNode,
  });
}
