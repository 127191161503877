import React from 'react';

export default function CheckIcon() {
  return (
    <svg
      width="8"
      height="8"
      fill="none"
      strokeWidth="3"
      strokeLinecap="round"
      viewBox="0 0 16 16"
    >
      <polyline points="1.5,8 6,13.5 14.5,2.5" />
    </svg>
  );
}
