import React from 'react';
import FilterName from 'routes/Potions/shared/filter/FilterName';
import FilterValue from 'routes/Potions/shared/filter/FilterValue';
import FilterIngredients from 'routes/Potions/shared/filter/FilterIngredients';
import FilterEffects from 'routes/Potions/shared/filter/FilterEffects';

export default function FilterRow({ filter }) {
  return (
    <tr>
      <td>
        <FilterName filter={filter} />
      </td>
      <td>
        <FilterValue filter={filter} />
      </td>
      <td>
        <FilterIngredients filter={filter} />
      </td>
      <td>
        <FilterEffects filter={filter} />
      </td>
    </tr>
  );
}
