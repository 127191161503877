module.exports = [
  {
    name: 'Abecean Longfin',
    nameFr: 'Abécéen à longues nageoires',
    value: 15,
    weight: 0.5,
    effects: [
      'Weakness to Frost',
      'Fortify Sneak',
      'Weakness to Poison',
      'Fortify Restoration',
    ],
  },
  {
    name: 'Alocasia Fruit',
    dlc: 'Rare Curios Creation',
    value: 22,
    weight: 0.25,
    effects: [
      'Regenerate Stamina',
      'Light',
      'Ravage Magicka',
      'Regenerate Health',
    ],
    multipliers: {
      'Regenerate Stamina': { magnitude: 1.2, duration: 1, value: 1.22 },
      'Ravage Magicka': { magnitude: 1.5, duration: 1, value: 1.56 },
      'Regenerate Health': { magnitude: 1.2, duration: 1, value: 1.22 },
    },
  },
  {
    name: 'Ambrosia',
    dlc: 'Rare Curios Creation',
    value: 15,
    weight: 0.1,
    effects: [
      'Restore Health',
      'Regenerate Health',
      'Fortify Health',
      'Cure Poison',
    ],
    multipliers: {
      'Restore Health': { magnitude: 1.2, duration: 1, value: 1.22 },
      'Regenerate Health': { magnitude: 1.2, duration: 1, value: 1.22 },
      'Fortify Health': { magnitude: 1.25, duration: 1, value: 1.27 },
      'Cure Poison': { magnitude: 1, duration: 1, value: 1.56 },
    },
  },
  {
    name: 'Ancestor Moth Wing',
    dlc: 'Dawnguard',
    value: 2,
    weight: 0.1,
    effects: [
      'Damage Stamina',
      'Fortify Conjuration',
      'Damage Magicka Regen',
      'Fortify Enchanting',
    ],
  },
  {
    name: 'Angelfish',
    dlc: 'Fishing Creation',
    value: 30,
    weight: 0.25,
    effects: [
      'Regenerate Health',
      'Resist Fire',
      'Fortify Marksman',
      'Waterbreathing',
    ],
  },
  {
    name: 'Angler Larvae',
    dlc: 'Fishing Creation',
    value: 1,
    weight: 0.25,
    effects: [
      'Lingering Damage Health',
      'Regenerate Stamina',
      'Waterbreathing',
      'Fortify Two-handed',
    ],
  },
  {
    name: 'Ash Creep Cluster',
    dlc: 'Dragonborn',
    value: 20,
    weight: 0.25,
    effects: [
      'Damage Stamina',
      'Invisibility',
      'Resist Fire',
      'Fortify Destruction',
    ],
  },
  {
    name: 'Ash Hopper Jelly',
    dlc: 'Dragonborn',
    value: 20,
    weight: 0.25,
    effects: [
      'Restore Health',
      'Fortify Light Armor',
      'Resist Shock',
      'Weakness to Frost',
    ],
  },
  {
    name: 'Ashen Grass Pod',
    dlc: 'Dragonborn',
    value: 1,
    weight: 0.1,
    effects: [
      'Resist Fire',
      'Weakness to Shock',
      'Fortify Lockpicking',
      'Fortify Sneak',
    ],
  },
  {
    name: 'Aster Bloom Core',
    dlc: 'Rare Curios Creation',
    value: 40,
    weight: 0.1,
    effects: [
      'Resist Magic',
      'Fortify Light Armor',
      'Fortify Block',
      'Paralysis',
    ],
    multipliers: {
      'Resist Magic': { magnitude: 2, duration: 1, value: 2.14 },
      'Fortify Light Armor': { magnitude: 2, duration: 1, value: 2.14 },
      'Fortify Block': { magnitude: 1.25, duration: 1, value: 1.27 },
      'Paralysis': { magnitude: 1, duration: 2, value: 2.14 },
    },
  },
  {
    name: 'Bear Claws',
    nameFr: "Griffes d'ours",
    value: 2,
    weight: 0.1,
    effects: [
      'Restore Stamina',
      'Fortify Health',
      'Fortify One-handed',
      'Damage Magicka Regen',
    ],
    multipliers: {
      'Restore Stamina': { magnitude: 0.8, duration: 1, value: 1 },
    },
  },
  {
    name: 'Bee',
    nameFr: 'Abeille',
    value: 3,
    weight: 0.1,
    effects: [
      'Restore Stamina',
      'Ravage Stamina',
      'Regenerate Stamina',
      'Weakness to Shock',
    ],
  },
  {
    name: 'Beehive Husk',
    nameFr: 'Ruche vide',
    value: 5,
    weight: 1,
    effects: [
      'Resist Poison',
      'Fortify Light Armor',
      'Fortify Sneak',
      'Fortify Destruction',
    ],
    multipliers: {
      'Resist Poison': { magnitude: 0.5, duration: 1, value: 1 },
    },
  },
  {
    name: "Berit's Ashes",
    nameFr: 'Cendres de Berit (?)',
    dlc: 'Quest*',
    value: 5,
    weight: 0.2,
    effects: [
      'Damage Stamina',
      'Resist Fire',
      'Fortify Conjuration',
      'Ravage Stamina',
    ],
  },
  {
    name: 'Bittergreen Petals',
    dlc: 'Rare Curios Creation',
    value: 25,
    weight: 0.1,
    effects: [
      'Lingering Damage Stamina',
      'Invisibility',
      'Cure Poison',
      'Damage Magicka',
    ],
    multipliers: {
      'Lingering Damage Stamina': { magnitude: 2, duration: 1, value: 2.14 },
      'Invisibility': { magnitude: 1, duration: 1.5, value: 1.56 },
      'Damage Magicka': { magnitude: 1.5, duration: 1, value: 1.56 },
    },
  },
  {
    name: 'Bleeding Crown',
    nameFr: 'Couronne Sanglante',
    value: 10,
    weight: 0.3,
    effects: [
      'Weakness to Fire',
      'Fortify Block',
      'Weakness to Poison',
      'Resist Magic',
    ],
  },
  {
    name: "Blind Watcher's Eye",
    dlc: 'Rare Curios Creation',
    value: 9,
    weight: 0.2,
    effects: [
      'Light',
      'Fortify Magicka',
      'Fortify Alteration',
      'Spell Absorption',
    ],
    multipliers: {
      'Fortify Magicka': { magnitude: 1.25, duration: 1, value: 1.27 },
      'Fortify Alteration': { magnitude: 1.25, duration: 1, value: 1.27 },
    },
  },
  {
    name: 'Bliss Bug Thorax',
    dlc: 'Saints & Seducers Creation',
    value: 1,
    weight: 0.1,
    effects: [
      'Weakness to Fire',
      'Resist Fire',
      'Fortify Heavy Armor',
      'Fortify Illusion',
    ],
    multipliers: {
      'Fortify Illusion': { magnitude: 0.25, duration: 1, value: 0.21 },
    },
  },
  {
    name: 'Blister Pod Cap',
    dlc: 'Rare Curios Creation',
    value: 25,
    weight: 0.1,
    effects: [
      'Restore Magicka',
      'Fortify Magicka',
      'Night Eye',
      'Invisibility',
    ],
    multipliers: {
      'Restore Magicka': { magnitude: 1.2, duration: 1, value: 1.22 },
      'Fortify Magicka': { magnitude: 1.25, duration: 1, value: 1.27 },
      'Invisibility': { magnitude: 1, duration: 1.5, value: 1.56 },
    },
  },
  {
    name: 'Blisterwort',
    nameFr: 'Cloquerille',
    value: 12,
    weight: 0.2,
    effects: ['Damage Stamina', 'Frenzy', 'Restore Health', 'Fortify Smithing'],
    multipliers: {
      'Restore Health': { magnitude: 0.6, duration: 1, value: 1 },
    },
  },
  {
    name: 'Bloodgrass',
    dlc: 'The Cause Creation',
    value: 25,
    weight: 0.1,
    effects: ['Invisibility', 'Resist Poison', 'Slow', 'Fortify Health'],
    multipliers: {
      'Fortify Health': { magnitude: 1.25, duration: 1, value: 1.27 },
    },
  },
  {
    name: 'Blue Butterfly Wing',
    nameFr: 'Aile de papillon bleu',
    value: 2,
    weight: 0.1,
    effects: [
      'Damage Stamina',
      'Fortify Conjuration',
      'Damage Magicka Regen',
      'Fortify Enchanting',
    ],
  },
  {
    name: 'Blue Dartwing',
    nameFr: 'Aile de fléchette bleue',
    value: 1,
    weight: 0.1,
    effects: ['Resist Shock', 'Fortify Pickpocket', 'Restore Health', 'Fear'],
  },
  {
    name: 'Blue Mountain Flower',
    nameFr: 'Lys des cimes bleu',
    value: 2,
    weight: 0.1,
    effects: [
      'Restore Health',
      'Fortify Conjuration',
      'Fortify Health',
      'Damage Magicka Regen',
    ],
  },
  {
    name: 'Boar Tusk',
    dlc: 'Dragonborn',
    value: 20,
    weight: 0.5,
    effects: ['Fortify Stamina', 'Fortify Health', 'Fortify Block', 'Frenzy'],
    multipliers: {
      'Fortify Stamina': { magnitude: 1.25, duration: 5, value: 7.5 },
      'Fortify Health': { magnitude: 1, duration: 5, value: 5.9 },
    },
  },
  {
    name: 'Bog Beacon',
    dlc: 'Rare Curios Creation',
    value: 20,
    weight: 0.1,
    effects: [
      'Restore Magicka',
      'Fortify Heavy Armor',
      'Fear',
      'Damage Stamina',
    ],
    multipliers: {
      'Restore Magicka': { magnitude: 1.2, duration: 1, value: 1.22 },
      'Fortify Heavy Armor': { magnitude: 1.5, duration: 1, value: 1.56 },
      'Fear': { magnitude: 2, duration: 1, value: 2.14 },
      'Damage Stamina': { magnitude: 1.33, duration: 1, value: 1.36 },
    },
  },
  {
    name: 'Bone Meal',
    nameFr: "Farine d'os",
    value: 5,
    weight: 0.5,
    effects: [
      'Damage Stamina',
      'Resist Fire',
      'Fortify Conjuration',
      'Ravage Stamina',
    ],
  },
  {
    name: 'Briar Heart',
    nameFr: 'Coeur de ronce',
    value: 20,
    weight: 0.5,
    effects: [
      'Restore Magicka',
      'Fortify Block',
      'Paralysis',
      'Fortify Magicka',
    ],
    multipliers: {
      'Fortify Block': { magnitude: 0.5, duration: 1, value: 1 },
    },
  },
  {
    name: "Bungler's Bane",
    dlc: 'Rare Curios Creation',
    value: 50,
    weight: 0.5,
    effects: ['Slow', 'Ravage Stamina', 'Damage Stamina Regen', 'Resist Magic'],
    multipliers: {
      'Slow': { magnitude: 1, duration: 2, value: 2.14 },
      'Ravage Stamina': { magnitude: 1.5, duration: 1, value: 3.34 },
      'Damage Stamina Regen': { magnitude: 1, duration: 2, value: 2.14 },
      'Resist Magic': { magnitude: 2, duration: 1, value: 2.14 },
    },
  },
  {
    name: 'Burnt Spriggan Wood',
    dlc: 'Dragonborn',
    value: 20,
    weight: 0.5,
    effects: [
      'Weakness to Fire',
      'Fortify Alteration',
      'Damage Magicka Regen',
      'Slow',
    ],
  },
  {
    name: 'Butterfly Wing',
    nameFr: 'Aile de papillon',
    value: 3,
    weight: 0.1,
    effects: [
      'Restore Health',
      'Fortify Barter',
      'Lingering Damage Stamina',
      'Damage Magicka',
    ],
  },
  {
    name: 'Canis Root',
    nameFr: 'Racine de canis',
    value: 5,
    weight: 0.1,
    effects: [
      'Damage Stamina',
      'Fortify One-handed',
      'Fortify Marksman',
      'Paralysis',
    ],
  },
  {
    name: 'Charred Skeever Hide',
    nameFr: 'Peau de Ragnar carbonisée',
    value: 1,
    weight: 0.5,
    effects: [
      'Restore Stamina',
      'Cure Disease',
      'Resist Poison',
      'Restore Health',
    ],
    multipliers: {
      'Cure Disease': { magnitude: 1, duration: 1, value: 0.36 },
    },
  },
  {
    name: 'Chaurus Eggs',
    nameFr: 'Oeuf de Chaurus',
    value: 10,
    weight: 0.2,
    effects: [
      'Weakness to Poison',
      'Fortify Stamina',
      'Damage Magicka',
      'Invisibility',
    ],
  },
  {
    name: 'Chaurus Hunter Antennae',
    dlc: 'Dawnguard',
    value: 2,
    weight: 0.1,
    effects: [
      'Damage Stamina',
      'Fortify Conjuration',
      'Damage Magicka Regen',
      'Fortify Enchanting',
    ],
  },
  {
    name: "Chicken's Egg",
    nameFr: 'Oeuf de poule',
    value: 2,
    weight: 0.5,
    effects: [
      'Resist Magic',
      'Damage Magicka Regen',
      'Waterbreathing',
      'Lingering Damage Stamina',
    ],
  },
  {
    name: 'Chokeberry',
    dlc: 'Rare Curios Creation',
    value: 15,
    weight: 0.1,
    effects: [
      'Damage Health',
      'Ravage Health',
      'Lingering Damage Health',
      'Weakness to Poison',
    ],
    multipliers: {
      'Damage Health': { magnitude: 1.5, duration: 1, value: 1.56 },
      'Ravage Health': { magnitude: 1.5, duration: 1, value: 3.34 },
      'Lingering Damage Health': { magnitude: 2, duration: 1, value: 2.14 },
      'Weakness to Poison': { magnitude: 1.5, duration: 1, value: 1.56 },
    },
  },
  {
    name: 'Chokeweed',
    dlc: 'Rare Curios Creation',
    value: 7,
    weight: 0.1,
    effects: [
      'Weakness to Frost',
      'Restore Stamina',
      'Cure Disease',
      'Damage Magicka',
    ],
    multipliers: {
      'Weakness to Frost': { magnitude: 1.33, duration: 1, value: 1.37 },
      'Restore Stamina': { magnitude: 1.2, duration: 1, value: 1.22 },
      'Cure Disease': { magnitude: 1, duration: 1, value: 0.36 },
      'Damage Magicka': { magnitude: 1.33, duration: 1, value: 1.37 },
    },
  },
  {
    name: 'Coda Flower',
    dlc: 'Rare Curios Creation',
    value: 30,
    weight: 0.1,
    effects: [
      'Damage Health',
      'Lingering Damage Stamina',
      'Ravage Magicka',
      'Fortify Carry Weight',
    ],
    multipliers: {
      'Damage Health': { magnitude: 1.5, duration: 1, value: 1.56 },
      'Lingering Damage Stamina': { magnitude: 2, duration: 1, value: 2.14 },
      'Ravage Magicka': { magnitude: 1.5, duration: 1, value: 1.56 },
      'Fortify Carry Weight': { magnitude: 1.25, duration: 1, value: 1.27 },
    },
  },
  {
    name: 'Comberry',
    dlc: 'Rare Curios Creation',
    value: 12,
    weight: 0.1,
    effects: [
      'Damage Stamina',
      'Spell Absorption',
      'Restore Magicka',
      'Fortify Destruction',
    ],
    multipliers: {
      'Damage Stamina': { magnitude: 1.33, duration: 1, value: 1.36 },
      'Restore Magicka': { magnitude: 1.2, duration: 1, value: 1.22 },
      'Fortify Destruction': { magnitude: 1.2, duration: 1, value: 1.22 },
    },
  },
  {
    name: 'Congealed Putrescence',
    dlc: 'Rare Curios Creation',
    value: 9,
    weight: 0.1,
    effects: [
      'Ravage Health',
      'Restore Magicka',
      'Weakness to Fire',
      'Fortify Conjuration',
    ],
    multipliers: {
      'Ravage Health': { magnitude: 1.5, duration: 1, value: 3.34 },
      'Restore Magicka': { magnitude: 1.2, duration: 1, value: 1.22 },
      'Weakness to Fire': { magnitude: 1.33, duration: 1, value: 1.36 },
      'Fortify Conjuration': { magnitude: 1.2, duration: 1, value: 1.22 },
    },
  },
  {
    name: 'Corkbulb Root',
    dlc: 'Rare Curios Creation',
    value: 30,
    weight: 0.1,
    effects: [
      'Paralysis',
      'Restore Health',
      'Resist Shock',
      'Fortify Marksman',
    ],
    multipliers: {
      'Paralysis': { magnitude: 1, duration: 2, value: 2.14 },
      'Restore Health': { magnitude: 1.2, duration: 1, value: 1.22 },
      'Resist Shock': { magnitude: 1.33, duration: 1, value: 1.36 },
      'Fortify Marksman': { magnitude: 1.25, duration: 1, value: 1.27 },
    },
  },
  {
    name: 'Creep Cluster',
    nameFr: 'Plante grimpante',
    value: 1,
    weight: 0.2,
    effects: [
      'Restore Magicka',
      'Damage Stamina Regen',
      'Fortify Carry Weight',
      'Weakness to Magic',
    ],
  },
  {
    name: 'Crimson Nirnroot',
    nameFr: 'Nirnroot Cramoisie',
    value: 10,
    weight: 0.2,
    effects: [
      'Damage Health',
      'Damage Stamina',
      'Invisibility',
      'Resist Magic',
    ],
    multipliers: {
      'Damage Health': { magnitude: 3, duration: 1, value: 3.3 },
      'Damage Stamina': { magnitude: 3, duration: 1, value: 1 },
    },
  },
  {
    name: 'Cyrodilic Spadetail',
    nameFr: 'Poisson combattant de Cyrodiil',
    value: 15,
    weight: 0.25,
    effects: ['Damage Stamina', 'Fortify Restoration', 'Fear', 'Ravage Health'],
  },
  {
    name: 'Daedra Heart',
    nameFr: 'Coeur de daedra',
    value: 250,
    weight: 0.5,
    effects: [
      'Restore Health',
      'Damage Stamina Regen',
      'Damage Magicka',
      'Fear',
    ],
  },
  {
    name: 'Daedra Silk',
    dlc: 'Rare Curios Creation',
    value: 50,
    weight: 0.5,
    effects: [
      'Lingering Damage Stamina',
      'Paralysis',
      'Night Eye',
      'Invisibility',
    ],
    multipliers: {
      'Lingering Damage Stamina': { magnitude: 2, duration: 1, value: 2.14 },
      'Paralysis': { magnitude: 1, duration: 2, value: 2.14 },
      'Invisibility': { magnitude: 1, duration: 1.5, value: 1.56 },
    },
  },
  {
    name: 'Daedra Venin',
    dlc: 'Rare Curios Creation',
    value: 50,
    weight: 0.2,
    effects: [
      'Ravage Health',
      'Paralysis',
      'Fortify Destruction',
      'Spell Absorption',
    ],
    multipliers: {
      'Ravage Health': { magnitude: 1.5, duration: 1, value: 3.34 },
      'Paralysis': { magnitude: 1, duration: 2, value: 2.14 },
      'Fortify Destruction': { magnitude: 1.2, duration: 1, value: 1.22 },
    },
  },
  {
    name: 'Daedroth Teeth',
    dlc: 'Rare Curios Creation',
    value: 50,
    weight: 0.5,
    effects: [
      'Resist Frost',
      'Light',
      'Damage Magicka Regen',
      'Regenerate Stamina',
    ],
    multipliers: {
      'Resist Frost': { magnitude: 1.33, duration: 1, value: 1.36 },
      'Damage Magicka Regen': { magnitude: 1, duration: 2, value: 2.14 },
      'Regenerate Stamina': { magnitude: 1.2, duration: 1, value: 1.22 },
    },
  },
  {
    name: 'Deathbell',
    nameFr: 'Grelot de la mort',
    value: 4,
    weight: 0.1,
    effects: ['Damage Health', 'Ravage Stamina', 'Slow', 'Weakness to Poison'],
    multipliers: {
      'Damage Health': { magnitude: 1.5, duration: 1, value: 1.6 },
      'Ravage Stamina': { magnitude: 1, duration: 1, value: 2.1 },
    },
  },
  {
    name: "Dragon's Tongue",
    nameFr: 'Langue de Dragon',
    value: 5,
    weight: 0.1,
    effects: [
      'Resist Fire',
      'Fortify Barter',
      'Fortify Illusion',
      'Fortify Two-handed',
    ],
  },
  {
    name: 'Dreugh Wax',
    dlc: 'Rare Curios Creation',
    value: 25,
    weight: 1,
    effects: [
      'Weakness to Magic',
      'Frenzy',
      'Fortify Enchanting',
      'Fortify Smithing',
    ],
    multipliers: {
      'Weakness to Magic': { magnitude: 1.5, duration: 1, value: 1.56 },
      'Frenzy': { magnitude: 1, duration: 2, value: 2.14 },
      'Fortify Enchanting': { magnitude: 2, duration: 1, value: 2.14 },
      'Fortify Smithing': { magnitude: 1.25, duration: 1, value: 1.27 },
    },
  },
  {
    name: 'Dwarven Oil',
    nameFr: 'Huile Dwemer',
    value: 15,
    weight: 0.25,
    effects: [
      'Weakness to Magic',
      'Fortify Illusion',
      'Regenerate Magicka',
      'Restore Magicka',
    ],
  },
  {
    name: 'Ectoplasm',
    nameFr: 'Ectoplasme',
    value: 25,
    weight: 0.1,
    effects: [
      'Restore Magicka',
      'Fortify Destruction',
      'Fortify Magicka',
      'Damage Health',
    ],
    multipliers: {
      'Fortify Destruction': { magnitude: 0.8, duration: 1, value: 1 },
    },
  },
  {
    name: 'Elves Ear',
    nameFr: "Oreille d'elfes",
    value: 10,
    weight: 0.1,
    effects: [
      'Restore Magicka',
      'Fortify Marksman',
      'Weakness to Frost',
      'Resist Fire',
    ],
  },
  {
    name: 'Elytra Ichor',
    dlc: 'Rare Curios Creation',
    value: 30,
    weight: 0.1,
    effects: ['Restore Magicka', 'Invisibility', 'Slow', 'Fear'],
    multipliers: {
      'Restore Magicka': { magnitude: 1.2, duration: 1, value: 1.22 },
      'Invisibility': { magnitude: 1, duration: 1.5, value: 1.56 },
      'Slow': { magnitude: 1, duration: 2, value: 2.14 },
      'Fear': { magnitude: 2, duration: 1, value: 2.14 },
    },
  },
  {
    name: 'Emperor Parasol Moss',
    dlc: 'Dragonborn',
    value: 1,
    weight: 0.25,
    effects: [
      'Damage Health',
      'Fortify Magicka',
      'Regenerate Health',
      'Fortify Two-handed',
    ],
    multipliers: {
      'Damage Health': { magnitude: 1.5, duration: 10, value: 19.7 },
    },
  },
  {
    name: 'Eye of Sabre Cat',
    nameFr: 'Oeil de Smilodon',
    value: 2,
    weight: 0.1,
    effects: [
      'Restore Stamina',
      'Ravage Health',
      'Damage Magicka',
      'Restore Health',
    ],
  },
  {
    name: 'Falmer Ear',
    nameFr: 'Oreilles de Falmer',
    value: 10,
    weight: 0.2,
    effects: [
      'Damage Health',
      'Frenzy',
      'Resist Poison',
      'Fortify Lockpicking',
    ],
  },
  {
    name: 'Felsaad Tern Feathers',
    dlc: 'Dragonborn',
    value: 15,
    weight: 0.1,
    effects: [
      'Restore Health',
      'Fortify Light Armor',
      'Cure Disease',
      'Resist Magic',
    ],
  },
  {
    name: 'Fire Petal',
    dlc: 'Rare Curios Creation',
    value: 30,
    weight: 0.1,
    effects: ['Damage Health', 'Resist Fire', 'Spell Absorption', 'Paralysis'],
    multipliers: {
      'Damage Health': { magnitude: 1.5, duration: 1, value: 1.56 },
      'Resist Fire': { magnitude: 1.33, duration: 1, value: 1.36 },
      'Paralysis': { magnitude: 1, duration: 2, value: 2.14 },
    },
  },
  {
    name: 'Fire Salts',
    nameFr: 'Sels de feu',
    value: 50,
    weight: 0.25,
    effects: [
      'Weakness to Frost',
      'Resist Fire',
      'Restore Magicka',
      'Regenerate Magicka',
    ],
  },
  {
    name: 'Flame Stalk',
    dlc: 'Rare Curios Creation',
    value: 25,
    weight: 0.1,
    effects: [
      'Restore Health',
      'Resist Frost',
      'Weakness to Fire',
      'Invisibility',
    ],
    multipliers: {
      'Restore Health': { magnitude: 1.2, duration: 1, value: 1.22 },
      'Resist Frost': { magnitude: 1.33, duration: 1, value: 1.36 },
      'Weakness to Fire': { magnitude: 1.33, duration: 1, value: 1.36 },
      'Invisibility': { magnitude: 1, duration: 1.5, value: 1.56 },
    },
  },
  {
    name: 'Fly Amanita',
    nameFr: 'Amanite Tue-mouches',
    value: 2,
    weight: 0.1,
    effects: [
      'Resist Fire',
      'Fortify Two-handed',
      'Frenzy',
      'Regenerate Stamina',
    ],
  },
  {
    name: 'Frost Mirriam',
    nameFr: 'Hivernelle',
    value: 1,
    weight: 0.1,
    effects: [
      'Resist Frost',
      'Fortify Sneak',
      'Ravage Magicka',
      'Damage Stamina Regen',
    ],
  },
  {
    name: 'Frost Salts',
    nameFr: 'Sels de givre',
    value: 100,
    weight: 0.25,
    effects: [
      'Weakness to Fire',
      'Resist Frost',
      'Restore Magicka',
      'Fortify Conjuration',
    ],
  },
  {
    name: 'Fungus Stalk',
    dlc: 'Rare Curios Creation',
    value: 30,
    weight: 0.1,
    effects: [
      'Restore Magicka',
      'Fortify Health',
      'Fortify Stamina',
      'Waterbreathing',
    ],
    multipliers: {
      'Restore Magicka': { magnitude: 1.2, duration: 1, value: 1.22 },
      'Fortify Health': { magnitude: 1.25, duration: 5, value: 7.5 },
      'Fortify Stamina': { magnitude: 1.25, duration: 5, value: 7.5 },
      'Waterbreathing': { magnitude: 1, duration: 1.6, value: 1.67 },
    },
  },
  {
    name: 'Garlic',
    nameFr: 'Ail',
    value: 1,
    weight: 0.25,
    effects: [
      'Resist Poison',
      'Fortify Stamina',
      'Regenerate Magicka',
      'Regenerate Health',
    ],
  },
  {
    name: 'Giant Lichen',
    nameFr: 'Lichen Géant',
    value: 5,
    weight: 0.25,
    effects: [
      'Weakness to Shock',
      'Ravage Health',
      'Weakness to Poison',
      'Restore Magicka',
    ],
  },
  {
    name: "Giant's Toe",
    nameFr: 'Orteil de Géant',
    value: 20,
    weight: 1,
    effects: [
      'Damage Stamina',
      'Fortify Health',
      'Fortify Carry Weight',
      'Damage Stamina Regen',
    ],
    multipliers: {
      'Fortify Health': { magnitude: 1, duration: 5, value: 5.9 },
    },
  },
  {
    name: 'Glassfish',
    dlc: 'Fishing Creation',
    value: 1,
    weight: 0.25,
    effects: [
      'Restore Magicka',
      'Invisibility',
      'Fortify Illusion',
      'Fortify Persuasion',
    ],
    multipliers: {
      'Fortify Illusion': { magnitude: 0.25, duration: 1, value: 0.21 },
    },
  },
  {
    name: 'Gleamblossom',
    dlc: 'Dawnguard',
    value: 5,
    weight: 0.1,
    effects: ['Resist Magic', 'Fear', 'Regenerate Health', 'Paralysis'],
  },
  {
    name: 'Glow Dust',
    nameFr: 'Poussière luisante',
    value: 20,
    weight: 0.5,
    effects: [
      'Damage Magicka',
      'Damage Magicka Regen',
      'Fortify Destruction',
      'Resist Shock',
    ],
  },
  {
    name: 'Glowing Mushroom',
    nameFr: 'Champignon phosphorescent',
    value: 5,
    weight: 0.2,
    effects: [
      'Resist Shock',
      'Fortify Destruction',
      'Fortify Smithing',
      'Fortify Health',
    ],
  },
  {
    name: 'Gnarl Bark',
    dlc: 'Rare Curios Creation',
    value: 15,
    weight: 0.1,
    effects: [
      'Damage Health',
      'Regenerate Health',
      'Fortify Heavy Armor',
      'Resist Fire',
    ],
    multipliers: {
      'Damage Health': { magnitude: 1.5, duration: 1, value: 1.56 },
      'Regenerate Health': { magnitude: 1.2, duration: 1, value: 1.22 },
      'Fortify Heavy Armor': { magnitude: 1.5, duration: 1, value: 1.56 },
      'Resist Fire': { magnitude: 1.33, duration: 1, value: 1.36 },
    },
  },
  {
    name: 'Gold Kanet',
    dlc: 'Rare Curios Creation',
    value: 30,
    weight: 0.1,
    effects: [
      'Paralysis',
      'Ravage Health',
      'Weakness to Frost',
      'Fortify Smithing',
    ],
    multipliers: {
      'Paralysis': { magnitude: 1, duration: 2, value: 2.14 },
      'Ravage Health': { magnitude: 1.5, duration: 1, value: 3.34 },
      'Weakness to Frost': { magnitude: 1.33, duration: 1, value: 1.36 },
      'Fortify Smithing': { magnitude: 1.25, duration: 1, value: 1.27 },
    },
  },
  {
    name: 'Goldfish',
    dlc: 'Fishing Creation',
    value: 10,
    weight: 0.25,
    effects: [
      'Restore Stamina',
      'Fortify Heavy Armor',
      'Waterbreathing',
      'Resist Frost',
    ],
  },
  {
    name: 'Grass Pod',
    nameFr: 'Cosse',
    value: 1,
    weight: 0.1,
    effects: [
      'Resist Poison',
      'Ravage Magicka',
      'Fortify Alteration',
      'Restore Magicka',
    ],
  },
  {
    name: 'Green Butterfly Wing',
    dlc: 'Saints & Seducers Creation',
    value: 25,
    weight: 0.1,
    effects: ['Restore Magicka', 'Fear', 'Slow', 'Invisibility'],
    multipliers: {
      'Slow': { magnitude: 1, duration: 6, value: 0.57 },
    },
  },
  {
    name: 'Hackle-Lo Leaf',
    dlc: 'Rare Curios Creation',
    value: 30,
    weight: 0.1,
    effects: [
      'Restore Stamina',
      'Paralysis',
      'Waterbreathing',
      'Fortify Restoration',
    ],
    multipliers: {
      'Restore Stamina': { magnitude: 1.2, duration: 1, value: 1.22 },
      'Paralysis': { magnitude: 1, duration: 2, value: 2.14 },
      'Waterbreathing': { magnitude: 1, duration: 1.6, value: 1.67 },
      'Fortify Restoration': { magnitude: 1.25, duration: 1, value: 1.27 },
    },
  },
  {
    name: 'Hagraven Claw',
    nameFr: "Serre d'Harfreuse",
    value: 20,
    weight: 0.25,
    effects: [
      'Resist Magic',
      'Lingering Damage Magicka',
      'Fortify Enchanting',
      'Fortify Barter',
    ],
  },
  {
    name: 'Hagraven Feathers',
    nameFr: "Plume d'Harfreuse",
    value: 20,
    weight: 0.1,
    effects: [
      'Damage Magicka',
      'Fortify Conjuration',
      'Frenzy',
      'Weakness to Shock',
    ],
  },
  {
    name: 'Hanging Moss',
    nameFr: 'Mousse barbue',
    value: 1,
    weight: 0.25,
    effects: [
      'Damage Magicka',
      'Fortify Health',
      'Damage Magicka Regen',
      'Fortify One-handed',
    ],
  },
  {
    name: 'Harrada',
    dlc: 'The Cause Creation',
    value: 25,
    weight: 0.1,
    effects: [
      'Damage Health',
      'Damage Magicka',
      'Paralysis',
      'Damage Magicka Regen',
    ],
    multipliers: {
      'Damage Health': { magnitude: 2, duration: 1, value: 2.14 },
      'Damage Magicka': { magnitude: 1.33, duration: 1, value: 1.36 },
    },
  },
  {
    name: 'Hawk Beak',
    nameFr: 'Bec de faucon',
    value: 15,
    weight: 0.25,
    effects: [
      'Restore Stamina',
      'Resist Frost',
      'Fortify Carry Weight',
      'Resist Shock',
    ],
  },
  {
    name: 'Hawk Feathers',
    nameFr: 'Plume de faucon',
    value: 15,
    weight: 0.1,
    effects: [
      'Cure Disease',
      'Fortify Light Armor',
      'Fortify One-handed',
      'Fortify Sneak',
    ],
    multipliers: {
      'Cure Disease': { magnitude: 1, duration: 1, value: 0.36 },
    },
  },
  {
    name: "Hawk's Egg",
    dlc: 'Hearthfire',
    value: 5,
    weight: 0.5,
    effects: [
      'Resist Magic',
      'Damage Magicka Regen',
      'Waterbreathing',
      'Lingering Damage Stamina',
    ],
  },
  {
    name: 'Heart of Order',
    dlc: 'Rare Curios Creation',
    value: 40,
    weight: 0.1,
    effects: [
      'Restore Health',
      'Fortify Health',
      'Fortify One-handed',
      'Fortify Two-handed',
    ],
    multipliers: {
      'Restore Health': { magnitude: 1.2, duration: 1, value: 1.22 },
      'Fortify Health': { magnitude: 1.25, duration: 5, value: 7.5 },
      'Fortify One-handed': { magnitude: 1.25, duration: 1, value: 1.27 },
      'Fortify Two-handed': { magnitude: 1.25, duration: 1, value: 1.27 },
    },
  },
  {
    name: 'Histcarp',
    nameFr: 'Carpe du Hist',
    value: 6,
    weight: 0.25,
    effects: [
      'Restore Stamina',
      'Fortify Magicka',
      'Damage Stamina Regen',
      'Waterbreathing',
    ],
  },
  {
    name: 'Honeycomb',
    nameFr: 'Rayon de miel',
    value: 5,
    weight: 1,
    effects: [
      'Restore Stamina',
      'Fortify Block',
      'Fortify Light Armor',
      'Ravage Stamina',
    ],
    multipliers: {
      'Fortify Block': { magnitude: 0.5, duration: 1, value: 1 },
    },
  },
  {
    name: 'Human Flesh',
    nameFr: 'Chair humaine',
    value: 1,
    weight: 0.25,
    effects: ['Damage Health', 'Paralysis', 'Restore Magicka', 'Fortify Sneak'],
  },
  {
    name: 'Human Heart',
    nameFr: 'Coeur humain',
    value: 0,
    weight: 1,
    effects: [
      'Damage Health',
      'Damage Magicka',
      'Damage Magicka Regen',
      'Frenzy',
    ],
  },
  {
    name: 'Hunger Tongue',
    dlc: 'Rare Curios Creation',
    value: 10,
    weight: 0.1,
    effects: [
      'Weakness to Fire',
      'Cure Disease',
      'Cure Poison',
      'Fortify Magicka',
    ],
    multipliers: {
      'Weakness to Fire': { magnitude: 1.33, duration: 2, value: 2.93 },
      'Cure Disease': { magnitude: 1, duration: 1, value: 0.36 },
      'Fortify Magicka': { magnitude: 1.25, duration: 1, value: 1.27 },
    },
  },
  {
    name: 'Hydnum Azure Giant Spore',
    dlc: 'Rare Curios Creation',
    value: 30,
    weight: 0.5,
    effects: ['Resist Frost', 'Fortify Health', 'Regenerate Health', 'Light'],
    multipliers: {
      'Resist Frost': { magnitude: 1.33, duration: 1, value: 1.36 },
      'Fortify Health': { magnitude: 1.25, duration: 5, value: 7.5 },
      'Regenerate Health': { magnitude: 1.2, duration: 1, value: 1.22 },
    },
  },
  {
    name: 'Hypha Facia',
    dlc: 'Rare Curios Creation',
    value: 23,
    weight: 0.1,
    effects: ['Weakness to Poison', 'Frenzy', 'Ravage Stamina', 'Resist Magic'],
    multipliers: {
      'Weakness to Poison': { magnitude: 1.5, duration: 1, value: 1.56 },
      'Frenzy': { magnitude: 1, duration: 2, value: 2.14 },
      'Ravage Stamina': { magnitude: 1.5, duration: 1, value: 3.34 },
      'Resist Magic': { magnitude: 2, duration: 1, value: 2.14 },
    },
  },
  {
    name: 'Ice Wraith Teeth',
    nameFr: 'Dent de Spectre des glaces',
    value: 30,
    weight: 0.25,
    effects: [
      'Weakness to Frost',
      'Fortify Heavy Armor',
      'Invisibility',
      'Weakness to Fire',
    ],
  },
  {
    name: 'Imp Gall',
    dlc: 'Rare Curios Creation',
    value: 15,
    weight: 0.2,
    effects: [
      'Damage Health',
      'Weakness to Fire',
      'Fortify Barter',
      'Cure Poison',
    ],
    multipliers: {
      'Damage Health': { magnitude: 1.5, duration: 1, value: 1.56 },
      'Weakness to Fire': { magnitude: 1.33, duration: 1, value: 1.36 },
      'Fortify Barter': { magnitude: 2, duration: 1, value: 2.14 },
    },
  },
  {
    name: 'Imp Stool',
    nameFr: 'Pied de lutin',
    value: 0,
    weight: 0.3,
    effects: [
      'Damage Health',
      'Lingering Damage Health',
      'Paralysis',
      'Restore Health',
    ],
    multipliers: {
      'Restore Health': { magnitude: 0.6, duration: 1, value: 1 },
    },
  },
  {
    name: 'Jarrin Root',
    nameFr: 'Racine de jarrin',
    dlc: 'Quest*',
    value: 10,
    weight: 0.5,
    effects: [
      'Damage Health',
      'Damage Magicka',
      'Damage Stamina',
      'Damage Magicka Regen',
    ],
    multipliers: {
      'Damage Health': { magnitude: 100, duration: 1, value: 158.5 },
    },
  },
  {
    name: 'Jazbay Grapes',
    nameFr: 'Raisin Jazbay',
    value: 1,
    weight: 0.2,
    effects: [
      'Weakness to Magic',
      'Fortify Magicka',
      'Regenerate Magicka',
      'Ravage Health',
    ],
  },
  {
    name: 'Juniper Berries',
    nameFr: 'Genièvres',
    value: 1,
    weight: 0.1,
    effects: [
      'Weakness to Fire',
      'Fortify Marksman',
      'Regenerate Health',
      'Damage Stamina Regen',
    ],
  },
  {
    name: 'Juvenile Mudcrab',
    dlc: 'Fishing Creation',
    value: 10,
    weight: 0.25,
    effects: [
      'Regenerate Stamina',
      'Fortify Carry Weight',
      'Cure Disease',
      'Fortify Two-handed',
    ],
  },
  {
    name: 'Kagouti Hide',
    dlc: 'Rare Curios Creation',
    value: 20,
    weight: 1,
    effects: [
      'Lingering Damage Stamina',
      'Night Eye',
      'Fortify Carry Weight',
      'Resist Shock',
    ],
    multipliers: {
      'Lingering Damage Stamina': { magnitude: 2, duration: 1, value: 2.14 },
      'Fortify Carry Weight': { magnitude: 1.25, duration: 1, value: 1.27 },
      'Resist Shock': { magnitude: 1.33, duration: 1, value: 1.36 },
    },
  },
  {
    name: 'Kresh Fiber',
    dlc: 'Rare Curios Creation',
    value: 30,
    weight: 0.1,
    effects: [
      'Weakness to Magic',
      'Slow',
      'Fortify Sneak',
      'Fortify Pickpocket',
    ],
    multipliers: {
      'Weakness to Magic': { magnitude: 1.5, duration: 1, value: 1.56 },
      'Slow': { magnitude: 1, duration: 2, value: 2.14 },
      'Fortify Sneak': { magnitude: 1.25, duration: 1, value: 1.27 },
      'Fortify Pickpocket': { magnitude: 1.25, duration: 1, value: 1.27 },
    },
  },
  {
    name: 'Large Antlers',
    value: 2,
    weight: 0.1,
    effects: [
      'Restore Stamina',
      'Fortify Stamina',
      'Slow',
      'Damage Stamina Regen',
    ],
  },
  {
    name: 'Lavender',
    nameFr: 'Lavande',
    value: 1,
    weight: 0.1,
    effects: [
      'Resist Magic',
      'Fortify Stamina',
      'Ravage Magicka',
      'Fortify Conjuration',
    ],
  },
  {
    name: 'Lichor',
    dlc: 'Rare Curios Creation',
    value: 15,
    weight: 0.1,
    effects: [
      'Restore Magicka',
      'Regenerate Magicka',
      'Fortify Magicka',
      'Spell Absorption',
    ],
    multipliers: {
      'Restore Magicka': { magnitude: 1.2, duration: 1, value: 1.22 },
      'Regenerate Magicka': { magnitude: 1.2, duration: 1, value: 1.22 },
      'Fortify Magicka': { magnitude: 1.25, duration: 1, value: 1.27 },
    },
  },
  {
    name: 'Luminous Russula',
    dlc: 'Rare Curios Creation',
    value: 25,
    weight: 0.2,
    effects: [
      'Lingering Damage Stamina',
      'Lingering Damage Health',
      'Waterbreathing',
      'Fear',
    ],
    multipliers: {
      'Lingering Damage Stamina': { magnitude: 2, duration: 1, value: 2.14 },
      'Lingering Damage Health': { magnitude: 2, duration: 1, value: 2.14 },
      'Waterbreathing': { magnitude: 1, duration: 1.6, value: 1.67 },
      'Fear': { magnitude: 2, duration: 1, value: 2.14 },
    },
  },
  {
    name: 'Luna Moth Wing',
    nameFr: 'Aile de noctuelle',
    value: 5,
    weight: 0.1,
    effects: [
      'Damage Magicka',
      'Fortify Light Armor',
      'Regenerate Health',
      'Invisibility',
    ],
  },
  {
    name: 'Lyretail Anthias',
    dlc: 'Fishing Creation',
    value: 30,
    weight: 0.25,
    effects: [
      'Restore Magicka',
      'Fortify Alteration',
      'Fortify Conjuration',
      'Fortify Carry Weight',
    ],
  },
  {
    name: 'Marshmerrow',
    dlc: 'Rare Curios Creation',
    value: 18,
    weight: 0.1,
    effects: [
      'Restore Health',
      'Fortify Carry Weight',
      'Weakness to Magic',
      'Damage Stamina',
    ],
    multipliers: {
      'Restore Health': { magnitude: 1.2, duration: 1, value: 1.22 },
      'Fortify Carry Weight': { magnitude: 1.25, duration: 1, value: 1.27 },
      'Weakness to Magic': { magnitude: 1.5, duration: 1, value: 1.56 },
      'Damage Stamina': { magnitude: 1.33, duration: 1, value: 1.36 },
    },
  },
  {
    name: 'Minotaur Horn',
    dlc: 'Rare Curios Creation',
    value: 55,
    weight: 5,
    effects: [
      'Resist Poison',
      'Damage Magicka Regen',
      'Regenerate Health',
      'Regenerate Magicka',
    ],
    multipliers: {
      'Resist Poison': { magnitude: 1.25, duration: 1, value: 1.27 },
      'Damage Magicka Regen': { magnitude: 1, duration: 2, value: 2.14 },
      'Regenerate Health': { magnitude: 1.2, duration: 1, value: 1.22 },
      'Regenerate Magicka': { magnitude: 1.2, duration: 1, value: 1.22 },
    },
  },
  {
    name: 'Moon Sugar',
    nameFr: 'sucrelune',
    value: 50,
    weight: 0.25,
    effects: [
      'Weakness to Fire',
      'Resist Frost',
      'Restore Magicka',
      'Regenerate Magicka',
    ],
  },
  {
    name: 'Mora Tapinella',
    nameFr: 'Mora Tapinella',
    value: 4,
    weight: 0.25,
    effects: [
      'Restore Magicka',
      'Lingering Damage Health',
      'Regenerate Stamina',
      'Fortify Illusion',
    ],
  },
  {
    name: 'Mort Flesh',
    dlc: 'Plague of the Dead Creation',
    value: 2,
    weight: 0.5,
    effects: [
      'Damage Health',
      'Damage Magicka',
      'Damage Magicka Regen',
      'Frenzy',
    ],
  },
  {
    name: 'Mudcrab Chitin',
    nameFr: 'Chitine de vasard',
    value: 2,
    weight: 0.25,
    effects: [
      'Restore Stamina',
      'Cure Disease',
      'Resist Poison',
      'Resist Fire',
    ],
  },
  {
    name: "Namira's Rot",
    nameFr: 'Truffe de Namira',
    value: 0,
    weight: 0.25,
    effects: [
      'Damage Magicka',
      'Fortify Lockpicking',
      'Fear',
      'Regenerate Health',
    ],
  },
  {
    name: 'Netch Jelly',
    dlc: 'Dragonborn',
    value: 20,
    weight: 0.5,
    effects: ['Paralysis', 'Fortify Carry Weight', 'Restore Stamina', 'Fear'],
    multipliers: {
      'Restore Stamina': { magnitude: 2, duration: 1, value: 1 },
    },
  },
  {
    name: 'Nightshade',
    nameFr: 'Obscurcine',
    value: 8,
    weight: 0.1,
    effects: [
      'Damage Health',
      'Damage Magicka Regen',
      'Lingering Damage Stamina',
      'Fortify Destruction',
    ],
    multipliers: {
      'Fortify Destruction': { magnitude: 0.8, duration: 1, value: 1 },
    },
  },
  {
    name: 'Nirnroot',
    nameFr: 'Nirnroot',
    value: 10,
    weight: 0.2,
    effects: [
      'Damage Health',
      'Damage Stamina',
      'Invisibility',
      'Resist Magic',
    ],
    multipliers: {
      'Damage Health': { magnitude: 1, duration: 10, value: 12.6 },
    },
  },
  {
    name: 'Nordic Barnacle',
    nameFr: 'Bernache Nordique',
    value: 5,
    weight: 0.2,
    effects: [
      'Damage Magicka',
      'Waterbreathing',
      'Regenerate Health',
      'Fortify Pickpocket',
    ],
  },
  {
    name: "Ogre's Teeth",
    dlc: 'Rare Curios Creation',
    value: 50,
    weight: 3,
    effects: [
      'Weakness to Shock',
      'Resist Poison',
      'Lingering Damage Magicka',
      'Regenerate Health',
    ],
    multipliers: {
      'Weakness to Shock': { magnitude: 1.33, duration: 1, value: 1.36 },
      'Resist Poison': { magnitude: 1.25, duration: 1, value: 1.27 },
      'Lingering Damage Magicka': { magnitude: 2, duration: 1, value: 2.12 },
      'Regenerate Health': { magnitude: 1.2, duration: 1, value: 1.22 },
    },
  },
  {
    name: 'Orange Dartwing',
    nameFr: 'Aile de fléchette orange',
    value: 1,
    weight: 0.1,
    effects: [
      'Restore Stamina',
      'Ravage Magicka',
      'Fortify Pickpocket',
      'Lingering Damage Health',
    ],
  },
  {
    name: 'Pearl',
    nameFr: 'Perle',
    value: 2,
    weight: 0.1,
    effects: [
      'Restore Stamina',
      'Fortify Block',
      'Restore Magicka',
      'Resist Shock',
    ],
  },
  {
    name: 'Pearlfish',
    dlc: 'Fishing Creation',
    value: 15,
    weight: 0.25,
    effects: [
      'Restore Stamina',
      'Resist Frost',
      'Fortify Smithing',
      'Fortify One-handed',
    ],
  },
  {
    name: 'Pine Thrush Egg',
    nameFr: 'Oeuf de grive',
    value: 2,
    weight: 0.5,
    effects: [
      'Restore Stamina',
      'Fortify Lockpicking',
      'Weakness to Poison',
      'Resist Shock',
    ],
  },
  {
    name: 'Poison Bloom',
    dlc: 'Dawnguard',
    value: 5,
    weight: 0.25,
    effects: ['Damage Health', 'Slow', 'Fortify Carry Weight', 'Fear'],
    multipliers: {
      'Damage Health': { magnitude: 1.5, duration: 1, value: 1.6 },
    },
  },
  {
    name: 'Powdered Mammoth Tusk',
    nameFr: 'Poudre de défense de mammouth',
    value: 2,
    weight: 0.1,
    effects: ['Restore Stamina', 'Fortify Sneak', 'Weakness to Fire', 'Fear'],
  },
  {
    name: 'Purple Butterfly Wing',
    dlc: 'Saints & Seducers Creation',
    value: 25,
    weight: 0.1,
    effects: [
      'Regenerate Health',
      'Regenerate Magicka',
      'Regenerate Stamina',
      'Paralysis',
    ],
  },
  {
    name: 'Purple Mountain Flower',
    nameFr: 'Lys des cimes violet',
    value: 2,
    weight: 0.1,
    effects: [
      'Restore Stamina',
      'Fortify Sneak',
      'Lingering Damage Magicka',
      'Resist Frost',
    ],
  },
  {
    name: 'Pygmy Sunfish',
    dlc: 'Fishing Creation',
    value: 2,
    weight: 0.25,
    effects: [
      'Restore Stamina',
      'Lingering Damage Magicka',
      'Damage Magicka Regen',
      'Fortify Restoration',
    ],
  },
  {
    name: 'Red Kelp Gas Bladder',
    dlc: 'Rare Curios Creation',
    value: 20,
    weight: 0.1,
    effects: [
      'Regenerate Stamina',
      'Waterbreathing',
      'Cure Disease',
      'Fortify Magicka',
    ],
    multipliers: {
      'Regenerate Stamina': { magnitude: 1.2, duration: 1, value: 1.22 },
      'Waterbreathing': { magnitude: 1, duration: 1.6, value: 1.67 },
      'Cure Disease': { magnitude: 1, duration: 1, value: 0.36 },
      'Fortify Magicka': { magnitude: 1.25, duration: 1, value: 1.27 },
    },
  },
  {
    name: 'Red Mountain Flower',
    nameFr: 'Lys des cimes rouge',
    value: 2,
    weight: 0.1,
    effects: [
      'Restore Magicka',
      'Ravage Magicka',
      'Fortify Magicka',
      'Damage Health',
    ],
  },
  {
    name: 'Redwort Flower',
    dlc: 'Rare Curios Creation',
    value: 25,
    weight: 0.1,
    effects: ['Resist Frost', 'Cure Poison', 'Damage Health', 'Invisibility'],
    multipliers: {
      'Resist Frost': { magnitude: 1.33, duration: 1, value: 1.36 },
      'Damage Health': { magnitude: 1.5, duration: 1, value: 1.56 },
      'Invisibility': { magnitude: 1, duration: 1.5, value: 1.56 },
    },
  },
  {
    name: 'River Betty',
    nameFr: 'Poisson de rivière',
    value: 15,
    weight: 0.25,
    effects: [
      'Damage Health',
      'Fortify Alteration',
      'Slow',
      'Fortify Carry Weight',
    ],
    multipliers: {
      'Damage Health': { magnitude: 2.5, duration: 10, value: 34.5 },
    },
  },
  {
    name: 'Rock Warbler Egg',
    nameFr: 'Oeuf de fauvette',
    value: 2,
    weight: 0.5,
    effects: [
      'Restore Health',
      'Fortify One-handed',
      'Damage Stamina',
      'Weakness to Magic',
    ],
  },
  {
    name: 'Roobrush',
    dlc: 'Rare Curios Creation',
    value: 20,
    weight: 0.1,
    effects: [
      'Weakness to Magic',
      'Fortify Sneak',
      'Lingering Damage Health',
      'Cure Poison',
    ],
    multipliers: {
      'Weakness to Magic': { magnitude: 1.5, duration: 1, value: 1.56 },
      'Fortify Sneak': { magnitude: 1.25, duration: 1, value: 1.27 },
      'Lingering Damage Health': { magnitude: 2, duration: 1, value: 2.14 },
    },
  },
  {
    name: 'Rot Scale',
    dlc: 'Saints & Seducers Creation',
    value: 20,
    weight: 0.1,
    effects: ['Slow', 'Lingering Damage Health', 'Fear', 'Paralysis'],
    multipliers: {
      'Slow': { magnitude: 1, duration: 3, value: 0.26 },
      'Lingering Damage Health': { magnitude: 1, duration: 3, value: 3.34 },
      'Fear': { magnitude: 1, duration: 0.16, value: 0.14 },
    },
  },
  {
    name: 'Sabre Cat Tooth',
    nameFr: 'Dent de smilodon',
    value: 2,
    weight: 0.1,
    effects: [
      'Restore Stamina',
      'Fortify Heavy Armor',
      'Fortify Smithing',
      'Weakness to Poison',
    ],
  },
  {
    name: 'Salmon Roe',
    dlc: 'Hearthfire',
    value: 5,
    weight: 0.2,
    effects: [
      'Restore Stamina',
      'Waterbreathing',
      'Fortify Magicka',
      'Regenerate Magicka',
    ],
    multipliers: {
      'Restore Stamina': { magnitude: 0.4, duration: 1, value: 0.36 },
      'Waterbreathing': { magnitude: 1, duration: 12, value: 15.4 },
      'Fortify Magicka': { magnitude: 12.5, duration: 0.08, value: 1.05 },
    },
  },
  {
    name: 'Salt Pile',
    nameFr: 'Tas de sel',
    value: 2,
    weight: 0.2,
    effects: [
      'Weakness to Magic',
      'Fortify Restoration',
      'Slow',
      'Regenerate Magicka',
    ],
  },
  {
    name: 'Saltrice',
    dlc: 'Rare Curios Creation',
    value: 6,
    weight: 0.1,
    effects: [
      'Restore Stamina',
      'Fortify Magicka',
      'Damage Stamina Regen',
      'Restore Health',
    ],
    multipliers: {
      'Restore Stamina': { magnitude: 1.2, duration: 1, value: 1.22 },
      'Fortify Magicka': { magnitude: 1.25, duration: 1, value: 1.27 },
      'Damage Stamina Regen': { magnitude: 1, duration: 2, value: 2.14 },
      'Restore Health': { magnitude: 1.2, duration: 1, value: 1.22 },
    },
  },
  {
    name: 'Scalon Fin',
    dlc: 'Rare Curios Creation',
    value: 25,
    weight: 0.1,
    effects: [
      'Waterbreathing',
      'Damage Health',
      'Lingering Damage Magicka',
      'Damage Magicka Regen',
    ],
    multipliers: {
      'Waterbreathing': { magnitude: 1, duration: 1.6, value: 1.67 },
      'Damage Health': { magnitude: 1.5, duration: 1, value: 1.56 },
      'Lingering Damage Magicka': { magnitude: 2, duration: 1, value: 2.14 },
      'Damage Magicka Regen': { magnitude: 1, duration: 2, value: 2.14 },
    },
  },
  {
    name: 'Scaly Pholiota',
    nameFr: 'Pholiote à écailles',
    value: 4,
    weight: 0.25,
    effects: [
      'Weakness to Magic',
      'Fortify Illusion',
      'Regenerate Stamina',
      'Fortify Carry Weight',
    ],
  },
  {
    name: 'Scathecraw',
    dlc: 'Dragonborn',
    value: 1,
    weight: 0.1,
    effects: [
      'Ravage Health',
      'Ravage Stamina',
      'Ravage Magicka',
      'Lingering Damage Health',
    ],
  },
  {
    name: 'Screaming Maw',
    dlc: 'Saints & Seducers Creation',
    value: 20,
    weight: 0.1,
    effects: [
      'Regenerate Magicka',
      'Fortify Alteration',
      'Invisibility',
      'Regenerate Health',
    ],
    multipliers: {
      'Regenerate Magicka': { magnitude: 4, duration: 0.2, value: 0.78 },
      'Fortify Alteration': { magnitude: 1.25, duration: 1, value: 1.27 },
      'Invisibility': { magnitude: 1, duration: 0.25, value: 0.22 },
      'Regenerate Health': { magnitude: 4, duration: 0.03, value: 0.11 },
    },
  },
  {
    name: 'Scrib Jelly',
    dlc: 'Rare Curios Creation',
    value: 20,
    weight: 0.1,
    effects: [
      'Regenerate Magicka',
      'Cure Poison',
      'Cure Disease',
      'Regenerate Stamina',
    ],
    multipliers: {
      'Regenerate Magicka': { magnitude: 1.2, duration: 1, value: 1.22 },
      'Cure Disease': { magnitude: 1, duration: 1, value: 0.36 },
      'Regenerate Stamina': { magnitude: 1.2, duration: 1, value: 1.22 },
    },
  },
  {
    name: 'Scrib Jerky',
    dlc: 'Rare Curios Creation',
    value: 15,
    weight: 0.2,
    effects: [
      'Restore Stamina',
      'Fortify Stamina',
      'Paralysis',
      'Waterbreathing',
    ],
    multipliers: {
      'Restore Stamina': { magnitude: 1.2, duration: 1, value: 1.22 },
      'Fortify Stamina': { magnitude: 1.25, duration: 1, value: 1.27 },
      'Paralysis': { magnitude: 1, duration: 2, value: 2.14 },
      'Waterbreathing': { magnitude: 1, duration: 1.6, value: 1.67 },
    },
  },
  {
    name: 'Silverside Perch',
    nameFr: 'Perche argentée',
    value: 15,
    weight: 0.25,
    effects: [
      'Restore Stamina',
      'Damage Stamina Regen',
      'Ravage Health',
      'Resist Frost',
    ],
  },
  {
    name: 'Skeever Tail',
    nameFr: 'Queue de Ragnard',
    value: 3,
    weight: 0.2,
    effects: [
      'Damage Stamina Regen',
      'Ravage Health',
      'Damage Health',
      'Fortify Light Armor',
    ],
  },
  {
    name: 'Slaughterfish Egg',
    nameFr: 'Oeuf de poisson carnassier',
    value: 3,
    weight: 0.2,
    effects: [
      'Resist Poison',
      'Fortify Pickpocket',
      'Lingering Damage Health',
      'Fortify Stamina',
    ],
  },
  {
    name: 'Slaughterfish Scales',
    nameFr: 'Ecailles de poisson carnassier',
    value: 3,
    weight: 0.1,
    effects: [
      'Resist Frost',
      'Lingering Damage Health',
      'Fortify Heavy Armor',
      'Fortify Block',
    ],
  },
  {
    name: 'Sload Soap',
    dlc: 'Rare Curios Creation',
    value: 30,
    weight: 0.1,
    effects: [
      'Resist Fire',
      'Fear',
      'Fortify Conjuration',
      'Fortify Alteration',
    ],
    multipliers: {
      'Resist Fire': { magnitude: 1.33, duration: 1, value: 1.36 },
      'Fear': { magnitude: 2, duration: 1, value: 2.14 },
      'Fortify Conjuration': { magnitude: 1.2, duration: 1, value: 1.22 },
      'Fortify Alteration': { magnitude: 1.25, duration: 1, value: 1.27 },
    },
  },
  {
    name: 'Small Antlers',
    nameFr: 'Petite ramure',
    value: 2,
    weight: 0.1,
    effects: [
      'Weakness to Poison',
      'Fortify Restoration',
      'Lingering Damage Stamina',
      'Damage Health',
    ],
  },
  {
    name: 'Small Pearl',
    nameFr: 'Petite perle',
    value: 2,
    weight: 0.1,
    effects: [
      'Restore Stamina',
      'Fortify One-handed',
      'Fortify Restoration',
      'Resist Frost',
    ],
  },
  {
    name: 'Snowberries',
    nameFr: 'givreboise',
    value: 4,
    weight: 0.1,
    effects: [
      'Resist Fire',
      'Fortify Enchanting',
      'Resist Frost',
      'Resist Shock',
    ],
  },
  {
    name: 'Spadefish',
    dlc: 'Fishing Creation',
    value: 15,
    weight: 0.25,
    effects: [
      'Restore Health',
      'Fortify Lockpicking',
      'Fortify Pickpocket',
      'Cure Disease',
    ],
  },
  {
    name: 'Spawn Ash',
    dlc: 'Dragonborn',
    value: 20,
    weight: 0.1,
    effects: [
      'Ravage Stamina',
      'Resist Fire',
      'Fortify Enchanting',
      'Ravage Magicka',
    ],
  },
  {
    name: 'Spiddal Stick',
    dlc: 'The Cause Creation',
    value: 25,
    weight: 0.1,
    effects: [
      'Damage Health',
      'Damage Magicka',
      'Weakness to Fire',
      'Restore Stamina',
    ],
    multipliers: {
      'Damage Health': { magnitude: 2, duration: 1, value: 2.14 },
      'Damage Magicka': { magnitude: 1.33, duration: 1, value: 1.36 },
    },
  },
  {
    name: 'Spider Egg',
    nameFr: "Oeuf d'araignée",
    value: 5,
    weight: 0.2,
    effects: [
      'Damage Stamina',
      'Damage Magicka Regen',
      'Fortify Lockpicking',
      'Fortify Marksman',
    ],
  },
  {
    name: 'Spriggan Sap',
    nameFr: 'Sève de spriggan',
    value: 15,
    weight: 0.2,
    effects: [
      'Damage Magicka Regen',
      'Fortify Enchanting',
      'Fortify Smithing',
      'Fortify Alteration',
    ],
  },
  {
    name: 'Steel-Blue Entoloma',
    dlc: 'Goblins Creation',
    value: 2,
    weight: 0.1,
    effects: [
      'Restore Magicka',
      'Fortify Destruction',
      'Resist Frost',
      'Fortify Carry Weight',
    ],
  },
  {
    name: 'Stoneflower Petals',
    dlc: 'Rare Curios Creation',
    value: 15,
    weight: 0.1,
    effects: [
      'Weakness to Shock',
      'Fortify One-handed',
      'Fortify Magicka',
      'Fortify Enchanting',
    ],
    multipliers: {
      'Weakness to Shock': { magnitude: 1.33, duration: 1, value: 1.36 },
      'Fortify One-handed': { magnitude: 1.25, duration: 1, value: 1.27 },
      'Fortify Magicka': { magnitude: 1.25, duration: 1, value: 1.27 },
      'Fortify Enchanting': { magnitude: 2, duration: 1, value: 2.14 },
    },
  },
  {
    name: 'Swamp Fungal Pod',
    nameFr: 'Mycène des marais',
    value: 5,
    weight: 0.25,
    effects: [
      'Resist Shock',
      'Lingering Damage Magicka',
      'Paralysis',
      'Restore Health',
    ],
  },
  {
    name: 'Taproot',
    nameFr: 'Racine noueuse',
    value: 15,
    weight: 0.5,
    effects: [
      'Weakness to Magic',
      'Fortify Illusion',
      'Regenerate Magicka',
      'Restore Magicka',
    ],
  },
  {
    name: 'Thistle Branch',
    nameFr: 'Tige de Chardon',
    value: 1,
    weight: 0.1,
    effects: [
      'Resist Frost',
      'Ravage Stamina',
      'Resist Poison',
      'Fortify Heavy Armor',
    ],
    multipliers: {
      'Resist Poison': { magnitude: 0.75, duration: 1, value: 1 },
    },
  },
  {
    name: 'Thorn Hook',
    dlc: 'Saints & Seducers Creation',
    value: 20,
    weight: 0.1,
    effects: [
      'Lingering Damage Health',
      'Paralysis',
      'Regenerate Magicka',
      'Regenerate Health',
    ],
    multipliers: {
      'Regenerate Magicka': { magnitude: 0.6, duration: 0.6, value: 0.32 },
      'Regenerate Health': { magnitude: 0.6, duration: 0.6, value: 0.32 },
    },
  },
  {
    name: 'Torchbug Thorax',
    nameFr: 'Thorax de flammouche',
    value: 1,
    weight: 0.1,
    effects: [
      'Restore Stamina',
      'Lingering Damage Magicka',
      'Weakness to Magic',
      'Fortify Stamina',
    ],
  },
  {
    name: 'Trama Root',
    dlc: 'Dragonborn',
    value: 1,
    weight: 0.2,
    effects: [
      'Weakness to Shock',
      'Fortify Carry Weight',
      'Damage Magicka',
      'Slow',
    ],
  },
  {
    name: 'Troll Fat',
    nameFr: 'Graisse de Troll',
    value: 15,
    weight: 1,
    effects: ['Resist Poison', 'Fortify Two-handed', 'Frenzy', 'Damage Health'],
  },
  {
    name: 'Tundra Cotton',
    nameFr: 'Coton sauvage',
    value: 1,
    weight: 0.1,
    effects: [
      'Resist Magic',
      'Fortify Magicka',
      'Fortify Block',
      'Fortify Barter',
    ],
  },
  {
    name: 'Vampire Dust',
    nameFr: 'Poussière de vampire',
    value: 25,
    weight: 0.2,
    effects: [
      'Invisibility',
      'Restore Magicka',
      'Regenerate Health',
      'Cure Disease',
    ],
  },
  {
    name: 'Void Essence',
    dlc: 'Rare Curios Creation',
    value: 60,
    weight: 0.2,
    effects: [
      'Restore Health',
      'Fortify Health',
      'Fortify Stamina',
      'Regenerate Health',
    ],
    multipliers: {
      'Restore Health': { magnitude: 1.2, duration: 1, value: 1.22 },
      'Fortify Health': { magnitude: 1.25, duration: 5, value: 7.5 },
      'Fortify Stamina': { magnitude: 1.25, duration: 5, value: 7.5 },
      'Regenerate Health': { magnitude: 1.2, duration: 1, value: 1.22 },
    },
  },
  {
    name: 'Void Salts',
    nameFr: 'Sel du Néant',
    value: 125,
    weight: 0.2,
    effects: [
      'Weakness to Shock',
      'Resist Magic',
      'Damage Health',
      'Fortify Magicka',
    ],
  },
  {
    name: "Watcher's Eye",
    dlc: 'Rare Curios Creation',
    value: 9,
    weight: 0.2,
    effects: [
      'Night Eye',
      'Fortify Magicka',
      'Fortify Illusion',
      'Spell Absorption',
    ],
    multipliers: {
      'Fortify Magicka': { magnitude: 1.25, duration: 1, value: 1.27 },
      'Fortify Illusion': { magnitude: 5, duration: 1, value: 5.87 },
    },
  },
  {
    name: 'Wheat',
    nameFr: 'Blé',
    value: 5,
    weight: 0.1,
    effects: [
      'Restore Health',
      'Fortify Health',
      'Damage Stamina Regen',
      'Lingering Damage Magicka',
    ],
  },
  {
    name: 'White Cap',
    nameFr: 'Chapeau blanc',
    value: 0,
    weight: 0.3,
    effects: [
      'Weakness to Frost',
      'Fortify Heavy Armor',
      'Restore Magicka',
      'Ravage Magicka',
    ],
  },
  {
    name: 'Wisp Stalk Caps',
    dlc: 'Rare Curios Creation',
    value: 20,
    weight: 0.1,
    effects: [
      'Damage Health',
      'Weakness to Poison',
      'Frenzy',
      'Regenerate Stamina',
    ],
    multipliers: {
      'Damage Health': { magnitude: 1.5, duration: 1, value: 1.56 },
      'Weakness to Poison': { magnitude: 1.5, duration: 1, value: 1.56 },
      'Frenzy': { magnitude: 1, duration: 2, value: 2.14 },
      'Regenerate Stamina': { magnitude: 1.2, duration: 1, value: 1.22 },
    },
  },
  {
    name: 'Wisp Wrappings',
    nameFr: 'Voiles éthérés',
    value: 2,
    weight: 0.1,
    effects: [
      'Restore Stamina',
      'Fortify Destruction',
      'Fortify Carry Weight',
      'Resist Magic',
    ],
  },
  {
    name: 'Withering Moon',
    dlc: 'Rare Curios Creation',
    value: 5,
    weight: 0.1,
    effects: [
      'Restore Magicka',
      'Spell Absorption',
      'Fortify Light Armor',
      'Cure Disease',
    ],
    multipliers: {
      'Restore Magicka': { magnitude: 1.2, duration: 1, value: 1.22 },
      'Fortify Light Armor': { magnitude: 1.5, duration: 1, value: 1.56 },
      'Cure Disease': { magnitude: 1, duration: 1, value: 0.36 },
    },
  },
  {
    name: "Worm's Head Cap",
    dlc: 'Rare Curios Creation',
    value: 30,
    weight: 0.2,
    effects: [
      'Fortify Lockpicking',
      'Night Eye',
      'Fortify Carry Weight',
      'Slow',
    ],
    multipliers: {
      'Fortify Lockpicking': { magnitude: 1.5, duration: 1, value: 1.56 },
      'Fortify Carry Weight': { magnitude: 1.25, duration: 1, value: 1.27 },
      'Slow': { magnitude: 1, duration: 2, value: 2.14 },
    },
  },
  {
    name: 'Yellow Mountain Flower',
    dlc: 'Dawnguard',
    value: 2,
    weight: 0.1,
    effects: [
      'Resist Poison',
      'Fortify Restoration',
      'Fortify Health',
      'Damage Stamina Regen',
    ],
    multipliers: {
      'Fortify Restoration': { magnitude: 1.25, duration: 1, value: 1 },
    },
  },
];
