import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  max-width: 400px;
  margin: 0 auto;

  @media (max-width: 1100px) {
    max-width: 700px;
  }
`;

export const Title = styled.div`
  font-size: 24px;
  padding: 40px;
  text-align: center;
`;

export const Options = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: center;

  > *:not(:first-child) {
    margin-left: 10px;
  }
`;

export const List = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const pillCss = css`
  cursor: pointer;
  font-size: 11px;

  margin: 2px;
  padding: 2px 4px 3px;

  border: 1px solid var(--dark-blue);
  border-radius: 4px;

  background-color: transparent;
  color: var(---dark-blue);

  &.solid {
    background-color: var(--dark-blue);
    color: var(--white);
  }

  &.secondary {
    color: var(--dark-blue);
    border-color: var(--bright-yellow);

    &.solid {
      background-color: var(--bright-yellow);
    }
  }
`;
export const PillButton = styled.button`
  ${pillCss}
`;
export const PillLink = styled(Link)`
  ${pillCss}

  text-decoration: none;
`;
