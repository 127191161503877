import React from 'react';
import styled from 'styled-components';

export const Banner = styled.div`
  text-align: center;

  --blue: #005bbb;
  --yellow: #ffd500;

  p {
    margin: 0;
    padding: 4rem;
    font-size: 2rem;
    font-weight: bold;
  }

  p.top {
    background: var(--blue);
    color: var(--yellow);
  }
  p.bottom {
    background: var(--yellow);
    color: var(--blue);
  }
`;

export default function UkraineBanner() {
  return (
    <Banner>
      <p className="top">Seek truth – don't believe Putin's lies!</p>
      <p className="bottom">The world stands with Ukraine!</p>
    </Banner>
  );
}
