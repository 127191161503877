import { useState } from 'react';

export function orderNumbersBy({
  key,
  direction = 'ascending',
  fn = x => x[key],
}) {
  return {
    ascending: (one, two) => fn(one) - fn(two),
    descending: (one, two) => fn(two) - fn(one),
  }[direction];
}

export function orderStringsBy({
  key,
  direction = 'ascending',
  fn = x => x[key],
}) {
  return {
    ascending: (one, two) =>
      fn(one) < fn(two) ? -1 : fn(one) > fn(two) ? 1 : 0,
    descending: (one, two) =>
      fn(one) > fn(two) ? -1 : fn(one) < fn(two) ? 1 : 0,
  }[direction];
}

export function orderBy({ key, direction, type, fn }) {
  switch (type) {
    case 'string':
      return orderStringsBy({ key, direction, fn });
    case 'number':
    default:
      return orderNumbersBy({ key, direction, fn });
  }
}

export function toggledDirection(order = {}, key) {
  return order.key !== key
    ? 'ascending'
    : order.direction === 'ascending'
    ? 'descending'
    : 'ascending';
}

export function toggleOrder(order, key, type, fn = void 0) {
  return {
    key,
    direction: toggledDirection(order, key),
    type,
    fn,
  };
}

export function indicateOrder(order, key) {
  return (
    order &&
    order.key === key &&
    { 'ascending': '▲', 'descending': '▼' }[order.direction]
  );
}

export function useOrder(initialState = void 0) {
  const [order, setOrder] = useState(initialState);

  function toggleOrderOf(key, type, fn) {
    setOrder(order => toggleOrder(order, key, type, fn));
  }
  return [order, toggleOrderOf];
}
