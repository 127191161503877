import React, { useState } from 'react';
import * as S from './UnavailableIngredients.style.js';
import * as T from 'styles/Table/Table.style.js';
import Button, { IconButton } from 'styles/Button/Button.style.js';
import Effect from 'styles/Effect/Effect.style.js';
import CheckIcon from 'icons/CheckIcon';

import { useStore } from 'pocki2';
import { localeNameStore } from 'store/sub/localeName';
import { unavailableIngredientsStore } from 'store/sub/ingredients';
import { addIngredient, addAllIngredients } from 'store/actions/ingredients';
import { useOrder, indicateOrder, orderBy } from 'util/ordering';

export default function UnavailableIngredients() {
  const { localeName } = useStore(localeNameStore);

  const [limitNofDisplayed, setLimitNofDisplayed] = useState(true);
  const limit = 5;

  const [order, toggleOrderOf] = useOrder();

  const unavailableIngredients = useStore(unavailableIngredientsStore);

  if (unavailableIngredients.length === 0) {
    return false;
  }

  const orderedIngredients = order
    ? unavailableIngredients.slice().sort(orderBy(order))
    : unavailableIngredients;

  const displayedIngredients = limitNofDisplayed
    ? orderedIngredients.slice(0, limit)
    : orderedIngredients;

  const isAboveLimit = orderedIngredients.length > limit;

  return (
    <T.Table style={{ color: 'var(--crimson)' }}>
      <caption>
        Unavailable Ingredients ({displayedIngredients.length} of{' '}
        {orderedIngredients.length}) &nbsp;
        {isAboveLimit &&
          (limitNofDisplayed ? (
            <S.LimitToggler onClick={() => setLimitNofDisplayed(false)}>
              Show all
            </S.LimitToggler>
          ) : (
            <S.LimitToggler onClick={() => setLimitNofDisplayed(true)}>
              Show only {limit}
            </S.LimitToggler>
          ))}
        <br />
        <Button onClick={addAllIngredients}>Add all</Button>
      </caption>
      <thead>
        <tr>
          <td />
          <td
            className="orderable"
            onClick={() => toggleOrderOf('name', 'string')}
          >
            Name{indicateOrder(order, 'name')}
          </td>
          <td
            className="orderable number"
            onClick={() => toggleOrderOf('value', 'number')}
          >
            Value{indicateOrder(order, 'value')}
          </td>
          <td>Effects</td>
        </tr>
      </thead>
      <tbody>
        {displayedIngredients.map(ingredient => (
          <tr key={ingredient.name}>
            <td>
              <IconButton onClick={() => addIngredient(ingredient.name)}>
                <span role="img" aria-label="Add">
                  <CheckIcon />
                </span>
              </IconButton>
            </td>
            <td>{localeName(ingredient)}</td>
            <td className="number">{ingredient.value}</td>
            <td>
              {ingredient.effects.map(effect => (
                <Effect key={effect.name} className={effect.concoction}>
                  {effect.name}
                </Effect>
              ))}
            </td>
          </tr>
        ))}
      </tbody>
    </T.Table>
  );
}
