import React from 'react';
import * as T from 'styles/Table/Table.style.js';
import Effect from 'styles/Effect/Effect.style.js';

import { useStore } from 'pocki2';
import { ingredientsForSelectedDLCsStore } from 'store/sub/ingredients';

import _effects from 'data/effects';
import { unique } from 'util/array';
import { useOrder, indicateOrder, orderBy } from 'util/ordering';

export const effectsForSelectedDLCsStore = ingredientsForSelectedDLCsStore
  .map(ingredients =>
    ingredients
      .flatMap(ingredient => ingredient.effects.map(effect => effect.name))
      .filter(unique)
  )
  .map(ingredientEffectNames =>
    _effects.filter(effect => ingredientEffectNames.includes(effect.name))
  );

export default function Effects() {
  const dlcEffects = useStore(effectsForSelectedDLCsStore);
  const [order, toggleOrderOf] = useOrder();
  const effects = order ? dlcEffects.slice().sort(orderBy(order)) : dlcEffects;

  return (
    <T.Table>
      <caption>All Effects</caption>
      <thead>
        <tr>
          <td
            className="orderable"
            onClick={() => toggleOrderOf('name', 'string')}
          >
            Name{indicateOrder(order, 'name')}
          </td>
          <td
            className="orderable number"
            onClick={() => toggleOrderOf('valueAt100Skill', 'number')}
          >
            Value{indicateOrder(order, 'valueAt100Skill')}
          </td>
          <td
            className="orderable number"
            onClick={() => toggleOrderOf('baseCost', 'number')}
          >
            Base cost{indicateOrder(order, 'baseCost')}
          </td>
          <td
            className="orderable number"
            onClick={() => toggleOrderOf('magnitude', 'number')}
          >
            Base magnitude{indicateOrder(order, 'magnitude')}
          </td>
          <td
            className="orderable number"
            onClick={() => toggleOrderOf('duration', 'number')}
          >
            Base duration{indicateOrder(order, 'duration')}
          </td>
        </tr>
      </thead>
      <tbody>
        {effects.map(effect => (
          <tr key={effect.name}>
            <td>
              <Effect className={effect.concoction}>{effect.name}</Effect>
            </td>
            <td className="number">{effect.valueAt100Skill}</td>
            <td className="number">{effect.baseCost.toFixed(2)}</td>
            <td className="number">{effect.magnitude}</td>
            <td className="number">{effect.duration}</td>
          </tr>
        ))}
      </tbody>
    </T.Table>
  );
}
