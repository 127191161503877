import React from 'react';
import * as S from 'components/PillToggler/PillToggler.js';

import { useStore } from 'pocki2';
import { localeNameStore } from 'store/sub/localeName';
import { taggedIngredientsStore } from 'store/sub/ingredients';
import { addIngredient, removeIngredient } from 'store/actions/ingredients';

function toggleIngredient(ingredient) {
  if (ingredient.isAvailable) {
    removeIngredient(ingredient.name);
  } else {
    addIngredient(ingredient.name);
  }
}

export default function IngredientPills() {
  const { localeName } = useStore(localeNameStore);
  const ingredients = useStore(taggedIngredientsStore);

  return (
    <S.List>
      {ingredients.map(ingredient => (
        <S.PillButton
          key={ingredient.name}
          onClick={() => toggleIngredient(ingredient)}
          className={ingredient.isAvailable ? 'solid' : ''}
        >
          {localeName(ingredient)}
        </S.PillButton>
      ))}
    </S.List>
  );
}
